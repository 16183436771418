import { DeviceReading, DeviceReadingFromSources } from '../device-reading/device-reading';
import { IDeviceType } from '../device-types/device-types';
import { Manufacturer } from '../manufacturer/manufacturer';
import { RadioKey } from '../radio-key/radio-key';
import { HistoricalValues } from '@wiot/shared-domain/models/device/historical-value';
import { Consumption } from '@wiot/shared-domain/domain/property/consumption';

// alphabetically
export enum DeviceStatus {
  APPLICATION_BUSY = 'APPLICATION_BUSY',
  APPLICATION_ERROR = 'APPLICATION_ERROR',
  BACKFLUSH = 'BACKFLUSH',
  BELOW_ZERO = 'BELOW_ZERO',
  BATTERY_DURATION_LESS_3_MONTH = 'BATTERY_DURATION_LESS_3_MONTH',
  CRITICAL_BATTERY = 'CRITICAL_BATTERY',
  CS_ERROR = 'CS_ERROR',
  END_OF_LIFE = 'END_OF_LIFE',
  ERROR = 'ERROR',
  FLOW_ERROR = 'FLOW_ERROR',
  HANDLING_ERROR = 'HANDLING_ERROR',
  HF_ERROR = 'HF_ERROR',
  INVALID_COMMUNICATION_INDICATOR = 'INVALID_COMMUNICATION_INDICATOR',
  INVALID_COMMUNICATION_INDICATOR_IR = 'INVALID_COMMUNICATION_INDICATOR_IR',
  INVALID_CONTROL_INFORMATION = 'INVALID_CONTROL_INFORMATION',
  INVALID_ENCRYPTION = 'INVALID_ENCRYPTION',
  INVALID_MESSAGE_CONTENT = 'INVALID_MESSAGE_CONTENT',
  LEAKAGE = 'LEAKAGE',
  LOW_BATTERY = 'LOW_BATTERY',
  MANIPULATION = 'MANIPULATION',
  MANUFACTURER_SPECIFIC_ERROR = 'MANUFACTURER_SPECIFIC_ERROR',
  MEASURE_ERROR = 'MEASURE_ERROR',
  NEGATIVE_POWER = 'NEGATIVE_POWER',
  NEGATIVE_TEMPERATURE_DIFF = 'NEGATIVE_TEMPERATURE_DIFF',
  NO_KEY = 'NO_KEY',
  NO_TELEGRAM_PARSED = 'NO_TELEGRAM_PARSED',
  OFFLINE = 'OFFLINE',
  OK = 'OK',
  PARSING_FAILED = 'PARSING_FAILED',
  PERMANENT_ERROR = 'PERMANENT_ERROR',
  PIPE_BREAKAGE = 'PIPE_BREAKAGE',
  POWER_LOW = 'POWER_LOW',
  POWER_FAIL = 'POWER_FAIL',
  RESERVED = 'RESERVED',
  RESET_ERROR = 'RESET_ERROR',
  RTC_INVALID = 'RTC_INVALID',
  STANDSTILL = 'STANDSTILL',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  SOFTWARE_ERROR = 'SOFTWARE_ERROR',
  HARDWARE_ERROR = 'HARDWARE_ERROR',
  TEMPERATURE_ERROR = 'TEMPERATURE_ERROR',
  TEMPORARY_ERROR = 'TEMPORARY_ERROR',
  VOLTAGE_DROP = 'VOLTAGE_DROP',
  WARNING = 'WARNING',
  WRONG_FLOW_DIRECTION = 'WRONG_FLOW_DIRECTION',
  DOUBLET_FILTER_OVERFLOW = 'DOUBLET_FILTER_OVERFLOW',
  TIME_SYNC_FAILED = 'TIME_SYNC_FAILED',
}

export function getDeviceStatuses(): string[] {
  return Object.values(DeviceStatus);
}

export interface DeviceState {
  message: string;
  timestamp: Date;
}

export interface Device {
  id: string;
  deviceId?: string;
  dinAddress?: string;
  deviceGroup?: any;
  /**
   * Names of the ancestors of the device group
   */
  deviceGroupAncestors?: string[];

  /**
   * Defines the type of the device.
   *
   * It's possible that a device doesn't have a device type. This might be cause for example by
   * an import of devices via a protocol without any device type information, e.g., LoRaWAN using a
   * manufacturer specific instead a standardized application layer protocol (like M-Bus).
   */
  deviceType?: IDeviceType;
  name?: string;
  manufacturer?: Manufacturer;
  createdAt?: Date;
  updatedAt?: Date;
  blacklisted?: boolean;
  radioKey?: any;
  states?: DeviceState[];
  status?: DeviceStatus[];
  gateways?: Device[];
  protocolType?: ProtocolType;
  deviceMetadata?: DeviceMetadata;
  isImported?: boolean;
  isDeprecated?: boolean;
  isToBeDeprecated?: boolean;
  oldDevice?: any;
  newDevice?: any;
  notes?: string;
  dataIntegrationJobID?: any;
  lastMessage?: DeviceReading;
  lastMessages?: DeviceReading[];
  lastMessageFromSources?: DeviceReadingFromSources;
  syncState?: string;
  version?: string;
  historicalDeviceTypeProfileDeviceReadingValues?: HistoricalValues[];
  communicationOptions?: ICommunicationOptions;
  consumption?: {
    evaluationFactor?: number;
    values?: Consumption;
  };
}

export interface ICommunicationOptions {
  secondaryRadioKey?: RadioKey;
  lora?: {
    joinEui?: string;
  };
}


export interface DeviceKeyPasswordRequest {
  downloadHash: string;
  password: string;
  email?: string;
}


export interface DeviceKey {
  deviceSerialNumber: string | undefined;
  deviceDinAddress: string | undefined;
  deviceName: string | undefined;
  deviceVersion: string;
  isVisible: boolean;
  mBusDeviceTypeId: number | undefined;
  keyValue: string;
  keyValueEncrypted?: string;
  keyName: string;
  deviceManufacturerFlagId: string;
}

export interface DeviceCreation extends Omit<Device, 'id'> {
  id?: string;
}

export type DeviceMetadata = {
  [key in DeviceMetadataFields]?: DeviceMetadataField[];
}

export enum DeviceMetadataFields {
  deviceDescription = 'deviceDescription',
  radioInfo = 'radioInfo',
  deviceInfo = 'deviceInfo',
  metadata = 'metadata',
}

export interface Devices {
  devices: Device[];
  totalPages: number;
  totalDocs: number;
}

export interface DeviceMetadataField {
  key: string;
  value: any;
}

export interface GroupedDevices {
  [key: string]: Device[];
}

export interface IColumnObject {
  name: string;
  visible: boolean;
  sortable: boolean;
  sort?: number;
}

export enum ProtocolType {
  OMS_DEVICE = '0',
  OMS_GATEWAY = '1',
  LORA_ABP_DEVICE = '2',
  LORA_GATEWAY = '3',
  LORA_OTAA_DEVICE = '4',
}

export function getProtocolTypes(): string[] {
  return Object.values(ProtocolType);
}

export enum DeviceViews {
  TABLE = 'TABLE_VIEW',
  MAP_VIEW = 'MAP_VIEW',
  TOPOLOGY = 'TOPOLOGY',
}

export enum DisplayTypes {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  BLOCKED = 'BLOCKED',
}

export const deviceFieldPermissionMap = {
  basic: [
    'name',
    'manufacturer',
    'deviceGroup',
    'radioKey',
    'gateways',
    'deviceType',
    'blacklisted',
    'status',
    'isDeprecated',
    'oldDevice',
    'newDevice',
    'notes',
  ],
  // the three below are under deviceMetadata
  metadata: ['metadata'],
  info: ['radioInfo', 'deviceInfo'],
  description: ['deviceDescription'],
  removeDeviceGroup: ['removeDeviceGroup'],
};

export interface IDeviceCountsByType {
  numberOfDevices: number;
  deviceType: IDeviceType | null;
}
