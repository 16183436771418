import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';

export const FETCH_DEVICE_GROUP = 'FETCH_DEVICE_GROUP';
export const DEVICE_GROUP_FETCHED = 'DEVICE_GROUP_FETCHED';
export const RESET_GROUP_SELECT = 'RESET_GROUP_SELECT';
export const DEVICE_GROUP_FETCH_FAILED = 'DEVICE_GROUP_FETCH_FAILED';

export type FetchDeviceGroupActionTypes =
  FetchDeviceGroupAction
  | DeviceGroupFetchedAction
  | DeviceGroupFetchFailed
  | ResetGroupSelectAction;

export interface FetchDeviceGroupAction {
  type: typeof FETCH_DEVICE_GROUP;
  deviceGroupId: string;
  groupSelectUuid: string;
}

export interface DeviceGroupFetchedAction {
  type: typeof DEVICE_GROUP_FETCHED;
  deviceGroup: DeviceGroup;
  groupSelectUuid: string;
}

export interface ResetGroupSelectAction {
  type: typeof RESET_GROUP_SELECT;
  groupSelectUuid: string;
}

export interface DeviceGroupFetchFailed {
  type: typeof DEVICE_GROUP_FETCH_FAILED;
  deviceGroupId: string;
  groupSelectUuid: string;
}

export const fetchDeviceGroupAction = (deviceGroupId: string, groupSelectUuid: string): FetchDeviceGroupAction => ({
  type: FETCH_DEVICE_GROUP,
  deviceGroupId,
  groupSelectUuid
});

export const resetGroupSelect = (groupSelectUuid: string): ResetGroupSelectAction => ({
  type: RESET_GROUP_SELECT,
  groupSelectUuid
});

export const deviceGroupFetched = (deviceGroup: DeviceGroup, groupSelectUuid: string): DeviceGroupFetchedAction => ({
  type: DEVICE_GROUP_FETCHED,
  deviceGroup: deviceGroup,
  groupSelectUuid
});

export const deviceGroupFetchFailed = (deviceGroupId: string, groupSelectUuid: string): DeviceGroupFetchFailed => ({
  type: DEVICE_GROUP_FETCH_FAILED,
  deviceGroupId,
  groupSelectUuid
});
