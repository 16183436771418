import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { Device } from '@wiot/shared-domain/models/device/device';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { AggregatedPermission, IRole } from '@wiot/shared-domain/models/role/role';
import { IUser, IUserWithPermissions } from '@wiot/shared-domain/models/user/user';
import { ISite } from '@wiot/shared-domain/models/settings/settings';
import { IDeviceType } from '@wiot/shared-domain/models/device-types/device-types';
import { DataIntegrationJob } from '@wiot/shared-domain/models/data-integration/data-integration';
import { DeviceReading } from '@wiot/shared-domain/models/device-reading/device-reading';
import { EmailCredentials, IdCredentials } from '@wiot/shared-domain/models/auth/auth-api';
import { DownloadDetails } from '@wiot/shared-domain/models/device-key/download-details';
import { ProtectionDetails } from '@wiot/shared-domain/models/device-key/protection-details';
import { IPaginationData } from './reducers/paginationReducer';
import { IFilterPayload, IRolesFilter, PageKeys } from './reducers/filterSortReducer';
import {
  ADD_DEVICE_TYPE,
  CHANGE_ENTRIES_PER_PAGE,
  CHANGE_FILTER,
  CHANGE_ONLINE_STATUS,
  CHANGE_SORT,
  CLEAR_PAGINATION,
  EDIT_USER_FETCHED_WITH_ROLES,
  FETCH_DEVICE_KEY_PROTECTION_INFO,
  FETCH_EDIT_USER_WITH_ROLES,
  FETCH_PERMITTED_ROLES,
  IS_LOADING,
  IS_MOBILE_DISPLAY,
  IS_OFFLINE,
  LOGOUT,
  PERMITTED_ROLES_FETCHED,
  RESET_DEVICE_MANAGER_STATE,
  RESET_FILTER,
  RESET_USER_ACTION_MODAL_STATE,
  SAVE_CURRENT_PAGE,
  SAVE_DATA_INTEGRATION_JOBS,
  SAVE_DEVICE_MESSAGES,
  SAVE_DEVICES,
  SAVE_KEYS,
  SAVE_PAGINATION,
  SAVE_ROLES,
  SAVE_USER_ROLES,
  SAVE_USERS,
  SERVER_FRONTEND_VERSION_FETCHED,
  SET_CURRENT_PAGETITLE,
  SET_CURRENT_USER,
  SET_LOADING_INDICATOR,
  SET_LOADING_MESSAGE,
  SET_MODAL_ZINDEX,
  SET_RECOMMENDED_ENTRIES_PER_PAGE,
  SET_SITE_SETTINGS,
  SET_USER_PERMISSION,
  TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS,
  TOGGLE_SELECT_ALL_DEVICES,
  TOGGLE_SELECT_ALL_KEYS,
  TOGGLE_SELECT_ALL_ROLES,
  TOGGLE_SELECT_ALL_USERS,
  TOGGLE_SELECT_DATA_INTEGRATION_JOB,
  TOGGLE_SELECT_DEVICE,
  TOGGLE_SELECT_KEY,
  TOGGLE_SELECT_ROLE,
  TOGGLE_SELECT_USER,
  TOGGLE_SHOW_DELETE_MODAL,
  TRY_LOGIN,
} from './constants/actionTypes';
import { DeviceTreeUpdatedAction } from './device-tree/fetch-device-tree/deviceTreeUpdatedAction';
import { NoResultsInfoActionTypes } from './device/fetching-limit/fetchNoResultInfoAction';
import { UserRole } from '@wiot/shared-domain/models/user/user-role';
import { DeleteManualDeviceReadingActionTypes } from './device-readings/DeleteManualDeviceReadingActionTypes';
import { LicensingActionTypes } from './licensing/LicensingActionTypes';
import { FeedbackActionTypes } from './feedback/feedbackActionTypes';
import { PropertyActionTypes } from './property-view/propertyViewActions';

import { IsTableLoadingActionTypes } from './table/isTableLoadingAction';
import {
  CREATE_DEVICE_PASSWORD,
  DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED,
  DEVICE_KEY_PROTECTION_INFO_FETCHED,
  DOWNLOAD_HASH_NOT_FOUND,
  FETCH_DEVICE_KEY_DOWNLOAD_DETAILS,
  HIDE_OR_SHOW_ALL_DEVICE_KEYS,
  HIDE_OR_SHOW_DEVICE_KEY
} from './device-key/deviceKeyActionTypes';
import { DeviceReadingsFetchedAction } from './device-readings/fetch-device-readings/fetchDeviceReadingsTypes';

export interface FetchOptions<T> {
  page?: number;
  pageSize?: number;
  filters?: T;
  sort?: string;
  gateways?: string | undefined;
}

export interface DeviceExtended extends Device {
  checked?: boolean;
  usersWithAccess?: IUser[];
}

export interface DeviceTypeExtended extends IDeviceType {
  checked?: boolean;
}

export interface DeviceGroupExtended extends DeviceGroup {
  checked?: boolean;
}

export interface UserExtended extends IUser {
  checked?: boolean;
}

export interface RadioKeyExtended extends RadioKey {
  checked?: boolean;
}

export type IPagination = Pick<IPaginationData, 'totalPages' | 'totalDocs'>;

export interface ILoadingMessage {
  message: string;
  translatable: boolean;
}

export interface IModalZindex {
  modalId: string;
  zIndex: number;
}

export enum Breakpoints {
  SM = 480,
  MD = 768,
  LG = 992,
  XL = 1024,
}

export type MobileBreakpoints = {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export type SetFieldValue<FormValues = any> = (
  field: keyof FormValues & string,
  value: any,
  shouldValidate?: boolean,
) => void;

export type OnlineState = boolean;
export type EntriesPerPageState = number;
export type RecommendedEntriesPerPageState = number;
export type ModalZindexState = IModalZindex;
export type DevicesState = Device[];
export type DeviceMessagesState = DeviceReading[];
export type DeviceGroupsState = DeviceGroup[];
export type UsersState = IUser[];
export type UserRolesState = UserRole[];
export type RolesState = IRole[];
export type KeysState = RadioKey[];
export type DataIntegrationJobsState = DataIntegrationJob[];
export type CurrentUserState = Partial<IUserWithPermissions>;
export type SiteSettingsState = ISite;
export type CurrentPageTitleState = PageKeys;
export type IsLoadingState = boolean;
export type LoadingMessageState = ILoadingMessage;
export type IsTableLoadingState = boolean;
export type IsMobileDisplay = boolean;
export type IsOfflineState = boolean;

interface SaveDevicesAction {
  type: typeof SAVE_DEVICES;
  payload: any[];
}

interface SaveDeviceMessagesAction {
  type: typeof SAVE_DEVICE_MESSAGES;
  payload: any[];
}

interface SaveUsersAction {
  type: typeof SAVE_USERS;
  payload: any[];
}

interface SaveUserRolesAction {
  type: typeof SAVE_USER_ROLES;
  payload: any[];
}

interface SaveRolesAction {
  type: typeof SAVE_ROLES;
  payload: any[];
}

interface SaveKeysAction {
  type: typeof SAVE_KEYS;
  payload: any[];
}

interface SaveDataIntegrationJobsAction {
  type: typeof SAVE_DATA_INTEGRATION_JOBS;
  payload: any[];
}

interface ToggleSelectDeviceAction {
  type: typeof TOGGLE_SELECT_DEVICE;
  payload: string;
}

interface ToggleSelectAllDevicesAction {
  type: typeof TOGGLE_SELECT_ALL_DEVICES;
  payload: boolean;
}


interface ToggleSelectUserAction {
  type: typeof TOGGLE_SELECT_USER;
  payload: string;
}

interface ToggleSelectAllUsersAction {
  type: typeof TOGGLE_SELECT_ALL_USERS;
  payload: boolean;
}

interface ToggleSelectRoleAction {
  type: typeof TOGGLE_SELECT_ROLE;
  payload: string;
}

interface ToggleSelectAllRolesAction {
  type: typeof TOGGLE_SELECT_ALL_ROLES;
  payload: boolean;
}

interface ToggleSelectKeyAction {
  type: typeof TOGGLE_SELECT_KEY;
  payload: string;
}

interface ToggleSelectAllKeysAction {
  type: typeof TOGGLE_SELECT_ALL_KEYS;
  payload: boolean;
}

interface ToggleSelectDataIntegrationJobsAction {
  type: typeof TOGGLE_SELECT_DATA_INTEGRATION_JOB;
  payload: string;
}

interface ToggleSelectAllDataIntegrationJobsAction {
  type: typeof TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS;
  payload: boolean;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  payload: IUser;
}

interface SetUserPermissionAction {
  type: typeof SET_USER_PERMISSION;
  payload: AggregatedPermission;
}

interface SetCurrentPageTitle {
  type: typeof SET_CURRENT_PAGETITLE;
  payload: PageKeys;
}

interface SetSiteSettingsAction {
  type: typeof SET_SITE_SETTINGS;
  payload: ISite;
}

interface SetRecommendedEntriesPerPageAction {
  type: typeof SET_RECOMMENDED_ENTRIES_PER_PAGE;
  payload: number;
}

interface SetModalZindexAction {
  type: typeof SET_MODAL_ZINDEX;
  payload: IModalZindex;
}

interface EntriesPerPageAction {
  type: typeof CHANGE_ENTRIES_PER_PAGE;
  payload: number;
}

export interface SavePaginationAction {
  type: typeof SAVE_PAGINATION;
  payload: {
    pageName: PageKeys;
    data: IPagination;
  };
}

export interface ClearPaginationAction {
  type: typeof CLEAR_PAGINATION;
  payload: {
    pageName: PageKeys;
  };
}

export interface SaveCurrentPageAction {
  type: typeof SAVE_CURRENT_PAGE;
  payload: {
    pageName: PageKeys;
    currentPage: number;
  };
}

interface IsLoadingAction {
  type: typeof IS_LOADING;
  payload: boolean;
}

interface SetLoadingMessageAction {
  type: typeof SET_LOADING_MESSAGE;
  payload: LoadingMessageState;
}

export interface ChangeFilterAction {
  type: typeof CHANGE_FILTER;
  payload: IFilterPayload;
}

interface ResetFilterAction {
  type: typeof RESET_FILTER;
  payload: IFilterPayload;
}

interface SaveSortAction {
  type: typeof CHANGE_SORT;
  payload: string;
}

interface SaveOnlineStatusAction {
  type: typeof CHANGE_ONLINE_STATUS;
  payload: boolean;
}

export interface AddDeviceTypeAction {
  type: typeof ADD_DEVICE_TYPE;
  payload: IDeviceType;
}

interface UpdateMobileDisplayAction {
  type: typeof IS_MOBILE_DISPLAY;
  payload: boolean;
}

interface UpdateIsOfflineAction {
  type: typeof IS_OFFLINE;
  payload: boolean;
}

export interface ResetDeviceManagerStateAction {
  type: typeof RESET_DEVICE_MANAGER_STATE;
}

export type SaveOnlineStatusActionTypes = SaveOnlineStatusAction;
export type FilterSortActionTypes = ChangeFilterAction | ResetFilterAction | SaveSortAction;
export type EntriesPerPageActionTypes = EntriesPerPageAction;
export type RecommendedEntriesPerPageTypes = SetRecommendedEntriesPerPageAction;
export type ModalZindexTypes = SetModalZindexAction;
export type LoginActionTypes = TryLoginAction | LogoutAction;
export type DevicesActionTypes =
  | SaveDevicesAction
  | ToggleSelectDeviceAction
  | ToggleSelectAllDevicesAction
  | DeviceReadingsFetchedAction;
export type DeviceMessagesActionTypes = SaveDeviceMessagesAction;

export type UsersActionTypes =
  | SaveUsersAction
  | ToggleSelectUserAction
  | ToggleSelectAllUsersAction;
export type UserRolesActionTypes = SaveUserRolesAction;
export type RolesActionTypes =
  | SaveRolesAction
  | ToggleSelectRoleAction
  | ToggleSelectAllRolesAction;
export type KeysActionTypes = SaveKeysAction | ToggleSelectKeyAction | ToggleSelectAllKeysAction;
export type DataIntegrationJobsActionTypes =
  | SaveDataIntegrationJobsAction
  | ToggleSelectDataIntegrationJobsAction
  | ToggleSelectAllDataIntegrationJobsAction;
export type UserPermissionActionTypes = SetUserPermissionAction;
export type CurrentPageTitleActionTypes = SetCurrentPageTitle;
export type SiteSettingsActionTypes = SetSiteSettingsAction;
export type PaginationActionTypes =
  | SavePaginationAction
  | ClearPaginationAction
  | SaveCurrentPageAction;

export type IsLoadingActionTypes = IsLoadingAction;
export type SetLoadingMessageActionTypes = SetLoadingMessageAction;
export type IsMobileDisplayActionTypes = UpdateMobileDisplayAction;
export type IsOfflineActionTypes = UpdateIsOfflineAction;

export interface FetchPermittedRolesAction {
  type: typeof FETCH_PERMITTED_ROLES;
  fetchOptions?: FetchOptions<IRolesFilter>;
}

export interface PermittedRolesFetchedAction {
  type: typeof PERMITTED_ROLES_FETCHED;
  permittedRoles: IRole[];
}

export interface FetchEditUserWithRolesAction {
  type: typeof FETCH_EDIT_USER_WITH_ROLES;
  userId: string;
}

export interface EditUserWithRolesFetchedAction {
  type: typeof EDIT_USER_FETCHED_WITH_ROLES;
  user: IUser;
}

export interface FetchDeviceKeyProtectionAction {
  type: typeof FETCH_DEVICE_KEY_PROTECTION_INFO;
  downloadHash: string;
}

export interface CreateDevicePasswordAction {
  type: typeof CREATE_DEVICE_PASSWORD;
  downloadHash: string;
}

export interface DeviceKeyProtectionDetailsFetchedAction {
  type: typeof DEVICE_KEY_PROTECTION_INFO_FETCHED;
  protectionDetails: ProtectionDetails;
}

export interface DownloadHashNotFoundAction {
  type: typeof DOWNLOAD_HASH_NOT_FOUND;
  downloadHash: string;
}

export interface FetchDeviceKeyDownloadDetailsAction {
  type: typeof FETCH_DEVICE_KEY_DOWNLOAD_DETAILS;
  downloadHash: string;
}

export interface HideOrShowDeviceKeyAction {
  type: typeof HIDE_OR_SHOW_DEVICE_KEY;
  deviceKeyIndex: number;
}

export interface HideOrShowAllKeysAction {
  type: typeof HIDE_OR_SHOW_ALL_DEVICE_KEYS;
  areAllKeysVisible: boolean;
}

export interface DeviceKeyDownloadDetailsFetchedAction {
  type: typeof DEVICE_KEY_DOWNLOAD_DETAILS_FETCHED;
  downloadDetails: DownloadDetails;
}

export interface TryLoginAction {
  type: typeof TRY_LOGIN;
  credentials: EmailCredentials | IdCredentials;
}

export interface SetShowLoadingIndicatorAction {
  type: typeof SET_LOADING_INDICATOR;
  isLoadingIndicatorEnabled: boolean;
}

export interface ToggleShowDeleteModalAction {
  type: typeof TOGGLE_SHOW_DELETE_MODAL;
}

export interface ResetUserActionModalStateAction {
  type: typeof RESET_USER_ACTION_MODAL_STATE;
}

export interface ServerFrontendVersionFetchedAction {
  type: typeof SERVER_FRONTEND_VERSION_FETCHED;
  version: string;
}

export type UserActionModalActionTypes =
  | FetchPermittedRolesAction
  | PermittedRolesFetchedAction
  | FetchEditUserWithRolesAction
  | EditUserWithRolesFetchedAction
  | ResetUserActionModalStateAction
  | ToggleShowDeleteModalAction
  | SetShowLoadingIndicatorAction;

export type DispatchActionTypes =
  | SaveDevicesAction
  | SaveDeviceMessagesAction
  | SaveUsersAction
  | SaveUserRolesAction
  | SaveRolesAction
  | SaveKeysAction
  | SaveDataIntegrationJobsAction
  | ToggleSelectDeviceAction
  | ToggleSelectAllDevicesAction
  | ToggleSelectUserAction
  | ToggleSelectAllUsersAction
  | ToggleSelectRoleAction
  | ToggleSelectAllRolesAction
  | ToggleSelectKeyAction
  | ToggleSelectAllKeysAction
  | ToggleSelectDataIntegrationJobsAction
  | ToggleSelectAllDataIntegrationJobsAction
  | LoginActionTypes
  | LogoutAction
  | SetCurrentUserAction
  | SetUserPermissionAction
  | SetRecommendedEntriesPerPageAction
  | SetModalZindexAction
  | SetCurrentPageTitle
  | SetSiteSettingsAction
  | EntriesPerPageAction
  | SavePaginationAction
  | ClearPaginationAction
  | IsLoadingActionTypes
  | SetLoadingMessageActionTypes
  | IsTableLoadingActionTypes
  | FilterSortActionTypes
  | AddDeviceTypeAction
  | IsMobileDisplayActionTypes
  | IsOfflineActionTypes
  | SaveCurrentPageAction
  | DeviceTreeUpdatedAction
  | FetchDeviceKeyProtectionAction
  | ResetDeviceManagerStateAction
  | DeleteManualDeviceReadingActionTypes
  | LicensingActionTypes
  | NoResultsInfoActionTypes
  | FeedbackActionTypes
  | PropertyActionTypes;
