import { Dispatch } from 'redux';
import { buildQueryString } from '../../../../utils/common';
import { authenticatedInstance, handleApiError } from '../../../../api/apiHelpers';
import { DEVICE_GROUPS_API } from '../../api-device-groups';
import { Ancestor, DeviceGroup, DeviceGroups, toAncestor } from '@wiot/shared-domain/models/device-group/device-group';
import {
  fetchGroupSelectMenu, GroupSelectMenu,
  groupSelectMenuFetched,
  groupSelectMenuFetchFailed
} from './fetch-group-select-menu-action-types';
import {
  VIRTUAL_ROOT_DEVICE_GROUP_ID,
  VIRTUAL_ROOT_DEVICE_GROUP_NAME
} from '@wiot/shared-domain/domain/device-tree/virtual-root-device-group-id';


const VIRTUAL_ROOT_GROUP = { name: VIRTUAL_ROOT_DEVICE_GROUP_NAME, id: VIRTUAL_ROOT_DEVICE_GROUP_ID };

export const fetchMenuWithTopLevelGroups = (
  groupSelectUuid: string,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch(fetchGroupSelectMenu(groupSelectUuid));
  try {
    let groupSelectMenu = await fetchMenuWithTopLevelDeviceGroupsFromApi();
    dispatch(groupSelectMenuFetched(groupSelectMenu, groupSelectUuid));
  } catch (error) {
    dispatch(groupSelectMenuFetchFailed(groupSelectUuid));
    handleApiError(error);
    throw error;
  }
};

export const fetchMenuWithGivenGroupAmongItsSiblings = (
  deviceGroupId: string,
  groupSelectUuid: string,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch(fetchGroupSelectMenu(groupSelectUuid));
  try {
    let groupSelectMenu = await fetchMenuWithGivenGroupAmongItsSiblingsFromApi(deviceGroupId);
    dispatch(groupSelectMenuFetched(groupSelectMenu, groupSelectUuid));
  } catch (error) {
    dispatch(groupSelectMenuFetchFailed(groupSelectUuid));
    handleApiError(error);
    throw error;
  }
};

export const fetchMenuWithChildrenOfGivenGroup = (
  deviceGroupId: string,
  groupSelectUuid: string,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch(fetchGroupSelectMenu(groupSelectUuid));
  try {
    let groupSelectMenu = await fetchMenuWithChildrenOfGivenGroupFromApi(deviceGroupId);
    dispatch(groupSelectMenuFetched(groupSelectMenu, groupSelectUuid));
  } catch (error) {
    dispatch(groupSelectMenuFetchFailed(groupSelectUuid));
    handleApiError(error);
    throw error;
  }
};

const fetchMenuWithGivenGroupAmongItsSiblingsFromApi = async (
  deviceGroupId: string,
): Promise<GroupSelectMenu> => {
  const qs = buildQueryString({
    appendUserAndDeviceInfo: false,
    appendAncestorGroups: true,
    appendChildGroups: true,
  });
  const url = `${ DEVICE_GROUPS_API }/parent/${ deviceGroupId }?${ qs }`;
  const { data: parentDeviceGroup } = await authenticatedInstance.get<DeviceGroup>(url);

  const hasRealAndPermittedParent = parentDeviceGroup.id !== VIRTUAL_ROOT_DEVICE_GROUP_ID;
  const pathGroups: Ancestor[] = [VIRTUAL_ROOT_GROUP];
  if (hasRealAndPermittedParent) {
    pathGroups.push(...parentDeviceGroup.ancestors!);
    pathGroups.push(toAncestor(parentDeviceGroup));
  }

  return {
    options: parentDeviceGroup.children!,
    pathGroups: pathGroups,
    deviceGroupId: parentDeviceGroup.id!,
    deviceGroupName: parentDeviceGroup.name || '',
  } as GroupSelectMenu;
};

const fetchMenuWithChildrenOfGivenGroupFromApi = async (
  deviceGroupId: string,
): Promise<GroupSelectMenu> => {
  const qs = buildQueryString({
    appendUserAndDeviceInfo: false,
    appendAncestorGroups: true,
    appendChildGroups: true,
  });
  const url = `${ DEVICE_GROUPS_API }/${ deviceGroupId }?${ qs }`;
  const { data: deviceGroup } = await authenticatedInstance.get<DeviceGroup>(url);
  const pathGroups = [
    VIRTUAL_ROOT_GROUP,
    ...deviceGroup.ancestors!,
    toAncestor(deviceGroup),
  ];

  return {
    options: deviceGroup.children!,
    pathGroups,
    deviceGroupId: deviceGroup.id!,
    deviceGroupName: deviceGroup.name || '',
  };
};

const fetchMenuWithTopLevelDeviceGroupsFromApi = async (): Promise<GroupSelectMenu> => {
  const { data: deviceGroups } = await authenticatedInstance.get<DeviceGroups>(DEVICE_GROUPS_API);
  return {
    options: deviceGroups.deviceGroups!,
    pathGroups: [],
    deviceGroupId: '',
    deviceGroupName: '',
  };
};