import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppState } from '../../../state/reducers/rootReducer';
import TableDataRowTooltip from '../../../components/TableDataRowTooltip';
import DeviceGroupPathCell from '../../../components/Table/DeviceGroupPathCell';

import RenderOnCondition from '../../../components/RenderOnCondition';
import DeviceActionModal from '../../DeviceManager/DeviceActionModal/DeviceActionModal';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import DeviceGroupDetailsModal from '../DeviceGroupDetailsModal';
import { DeviceGroupExtended } from '../../../state/types';
import { getDeviceGroupPathFromAncestors } from '../../../utils/device-group-path-helper';
import DeviceGroupActionModal from '../DeviceGroupActionModal';
import UserActionModal from '../../UserManagement/UserActionModal/UserActionModal';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { getSelectedMetaDataValue } from '../../DeviceManager/DeviceActionModal/metaDataUtilityFunctions';
import { DeviceMetadataFields } from '@wiot/shared-domain/models/device/device';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { PortalMenu } from '../../../components/PortalMenu/PortalMenu';
import TableMenu from './TableMenu';
import {
  HORIZONTAL_OFFSET_TABLE_VIEW,
  VERTICAL_OFFSET_TABLE_VIEW
} from '../../../components/PortalMenu/constants/offset-table-view';
import { getMenuId } from '../../../components/PortalMenu/constants/context-menu-ids';
import {
  closeContextMenu,
  openContextMenu,

} from '../../../state/context-menu/toggleContextMenuActionCreators';
import { toggleContextMenuActionTypes } from '../../../state/context-menu/toggleContextMenuAction';
import { fetchDeviceGroups } from '../../../state/device-group/paginated-device-groups/fetchDeviceGroupsActionCreators';

export interface TableDataRowProps extends RouteComponentProps {
  deviceGroup: DeviceGroupExtended;
  selectOne: (id: string) => void;
  removeUnit: (id: string) => Promise<void>;
  refreshGroups: () => void;
  isKeyManagerModeEnabled: boolean;
  visibleContextMenu?: string;
  openContextMenu: (id: string) => toggleContextMenuActionTypes;
  closeContextMenu: () => toggleContextMenuActionTypes;
}

export interface TableDataRowState {
  showDetails: boolean;
  toggleMenuButtonBoundingClientRect: DOMRect;
  showDeviceGroupActionModal: boolean;
  showDeviceGroupActionModalAddGroupInGroup: boolean;
  showDeviceActionModalAddDeviceInGroup: boolean;
  showUserActionModalAddUserInGroup: boolean;
  showDeleteModal: boolean;
  downloadHash?: string;
}

class TableDataRow extends React.Component<TableDataRowProps, TableDataRowState> {
  toggleMenuButton: HTMLDivElement | null;

  constructor(props: TableDataRowProps) {
    super(props);

    const { deviceGroup } = props;
    const downloadHash = getSelectedMetaDataValue(DeviceMetadataFields.metadata, 'downloadHash', deviceGroup);

    this.state = {
      showDetails: false,
      toggleMenuButtonBoundingClientRect: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        toJSON: () => null,
      },
      showDeviceGroupActionModalAddGroupInGroup: false,
      showDeviceGroupActionModal: false,
      showDeviceActionModalAddDeviceInGroup: false,
      showUserActionModalAddUserInGroup: false,
      showDeleteModal: false,
      downloadHash,
    };
  }

  closeMenu = (event?: React.MouseEvent) => {
    event && event.stopPropagation();
    this.props.closeContextMenu();
  }

  toggleDeviceGroupActionModal = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeviceGroupActionModal: !prevState.showDeviceGroupActionModal,
    }));

    this.closeMenu();
  };

  toggleDeviceGroupActionModalAddGroupInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeviceGroupActionModalAddGroupInGroup: !prevState.showDeviceGroupActionModalAddGroupInGroup,
    }));
  };

  toggleDeviceActionModalAddDeviceInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeviceActionModalAddDeviceInGroup: !prevState.showDeviceActionModalAddDeviceInGroup,
    }));
  };

  toggleUserActionModalAddUserInGroup = (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showUserActionModalAddUserInGroup: !prevState.showUserActionModalAddUserInGroup,
    }));
  };

  toggleDeleteModal = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  };

  toggleDetails = (event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent) => {
    if (event) {
      event.stopPropagation();
    }
    this.setState((prevState: TableDataRowState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  getBoundingClientRect = () => {
    if (this.toggleMenuButton) {
      this.setState(
        {
          toggleMenuButtonBoundingClientRect: this.toggleMenuButton.getBoundingClientRect(),
        },
        () => {},
      );
    }
  };

  render() {
    const { deviceGroup, selectOne, removeUnit, visibleContextMenu } = this.props;
    const { id, name, type, checked, abbreviation, externalId, hasChildren } = deviceGroup;
    const {
      showDeviceActionModalAddDeviceInGroup,
      showDeviceGroupActionModalAddGroupInGroup,
      showDeviceGroupActionModal,
      showUserActionModalAddUserInGroup,
      showDeleteModal,
      toggleMenuButtonBoundingClientRect,
      showDetails,
      downloadHash,
    } = this.state;

    const menuId = getMenuId(this.props.deviceGroup.id!);
    const showMenu = (visibleContextMenu === menuId);
    const toggleMenu = async () => {
      if (!showMenu) {
        await this.getBoundingClientRect();
        this.props.openContextMenu(menuId);
        return;
      }
      this.closeMenu();
    };

    return (
      <tr key={id} id={id} className="device-manager__table__table__trow">
        <td>
          <CustomCheckbox onChange={() => selectOne(id!)} checked={checked} />
        </td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={this.toggleDetails}
        >
          {name || '-'}
        </td>

        <RenderOnCondition condition={!this.props.isKeyManagerModeEnabled} >
          <td
            className="device-manager__table__table__td device-manager__table__table__link"
            onClick={this.toggleDetails}
          >
            {abbreviation || '-'}
          </td>
        </RenderOnCondition>

        <td>{type ? <Translate>{({ translate }) => translate(String(type))}</Translate> : '-'}</td>

        <td
          className="device-manager__table__table__td device-manager__table__table__link"
          onClick={this.toggleDetails}
        >
          {externalId || '-'}
        </td>

        {id && (
          <DeviceGroupPathCell
            id={id}
            deviceGroupPath={ getDeviceGroupPathFromAncestors(deviceGroup.ancestors || []) }
          />
        )}

        <td>
          <div className="device-details">
            <div
              className="ellipsis text-color-main"
              style={{ margin: '0 auto' }}
              role="presentation"
              ref={(tc) => {
                this.toggleMenuButton = tc;
              }}
              onClick={toggleMenu}
              data-tip="table-actions-button-tooltip"
              data-for="table-actions-button-tooltip"
            >
              <FontAwesomeIcon icon={faCog} />
            </div>

            <TableDataRowTooltip id={"table-actions-button-tooltip"} delayUntilShow={100}>
              <Translate id="actions" />
            </TableDataRowTooltip>

            <RenderOnCondition condition={showMenu}>
              <PortalMenu
                anchorPosition={{
                  left: toggleMenuButtonBoundingClientRect.left + HORIZONTAL_OFFSET_TABLE_VIEW,
                  top: toggleMenuButtonBoundingClientRect.top + window.scrollY + VERTICAL_OFFSET_TABLE_VIEW
                }}
                minWidth={ 350 }
              >
                <TableMenu
                  deviceGroupData={ deviceGroup }
                  removeUnit={ (itemId: string) => removeUnit(itemId) }
                  menuId={ menuId }
                  toggleDetails={ this.toggleDetails }
                  toggleDeviceGroupActionModal={ this.toggleDeviceGroupActionModal }
                  toggleDeleteModal={ this.toggleDeleteModal }
                  toggleDeviceGroupActionModalAddGroupInGroup={ this.toggleDeviceGroupActionModalAddGroupInGroup }
                  toggleDeviceActionModalAddDeviceInGroup={ this.toggleDeviceActionModalAddDeviceInGroup }
                  toggleUserActionModalAddUserInGroup={ this.toggleUserActionModalAddUserInGroup }
                  downloadHash={ downloadHash }
                />
              </PortalMenu>
            </RenderOnCondition>
            <RenderOnCondition condition={showDetails}>
              <DeviceGroupDetailsModal
                closeModal={this.toggleDetails}
                deviceGroupData={deviceGroup}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceActionModalAddDeviceInGroup}>
              <DeviceActionModal
                closeAddAndUpdateModal={this.toggleDeviceActionModalAddDeviceInGroup}
                title="add-dev-under-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={{ id }}
                disableGroupSelect
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showDeviceGroupActionModal}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={this.toggleDeviceGroupActionModal}
                title="update-group"
                showDeleteButton
                addUnit={false}
                removeUnit={(itemId: string) => removeUnit(itemId)}
                id={id}
                refreshGroups={()=>this.props.refreshGroups()}
              />
            </RenderOnCondition>
            <ConfirmModal
              show={showDeleteModal}
              modalCloseRequested={this.toggleDeleteModal}
              actionConfirmed={() => removeUnit(id!)}
              translationIdOfElementType="device-group"
              translationIdOfAdditionalInfo={ hasChildren ? 'confirm-to-delete-group' : '' }
              confirmationVariant={ ConfirmationVariant.DELETE }
            />
            <RenderOnCondition condition={showDeviceGroupActionModalAddGroupInGroup}>
              <DeviceGroupActionModal
                closeAddAndUpdateModal={this.toggleDeviceGroupActionModalAddGroupInGroup}
                title="add-group"
                showDeleteButton={false}
                addUnit
                selectedDeviceGroup={id}
                disableGroupSelect
                refreshGroups={()=>this.props.refreshGroups()}
              />
            </RenderOnCondition>
            <RenderOnCondition condition={showUserActionModalAddUserInGroup}>
              <UserActionModal
                saved={() => {
                  this.props.refreshGroups();
                  this.toggleUserActionModalAddUserInGroup();
                }}
                cancelled={this.toggleUserActionModalAddUserInGroup}
                isInCreationMode
                title="add-user-under-group"
                showDeleteButton={false}
                selectedDeviceGroupId={id}
              />
            </RenderOnCondition>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  visibleContextMenu: state.contextMenu.visibleContextMenuId,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  openContextMenu: (id: string) => dispatch(openContextMenu(id)),
  closeContextMenu: () => dispatch(closeContextMenu()),
  refreshGroups: () => dispatch(fetchDeviceGroups())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableDataRow));
