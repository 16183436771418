import { DeviceGroups } from '@wiot/shared-domain/models/device-group/device-group';

export const FETCH_DEVICE_GROUPS = 'FETCH_DEVICE_GROUPS';
export const DEVICE_GROUPS_FETCHED = 'DEVICE_GROUPS_FETCHED';
export const DEVICE_GROUPS_RESET = 'DEVICE_GROUPS_RESET';
export const DEVICE_GROUPS_FETCH_FAILED = 'DEVICE_GROUPS_FETCH_FAILED';

export const TOGGLE_SELECT_DEVICE_GROUP = 'TOGGLE_SELECT_DEVICE_GROUP';
export const TOGGLE_SELECT_ALL_DEVICE_GROUPS = 'TOGGLE_SELECT_ALL_DEVICE_GROUPS';


export type FetchDeviceGroupsActionTypes =
  FetchDeviceGroupsAction
  | DeviceGroupsFetchedAction
  | DeviceGroupsFetchFailedAction
  | DeviceGroupsResetAction
  | ToggleSelectDeviceGroupAction
  | ToggleSelectAllDeviceGroupsAction;

export interface FetchDeviceGroupsAction {
  type: typeof FETCH_DEVICE_GROUPS;
}

export interface DeviceGroupsFetchedAction {
  type: typeof DEVICE_GROUPS_FETCHED;
  deviceGroups: DeviceGroups;
}

export interface DeviceGroupsResetAction {
  type: typeof DEVICE_GROUPS_RESET;
}

export interface DeviceGroupsFetchFailedAction {
  type: typeof DEVICE_GROUPS_FETCH_FAILED;
}

export interface ToggleSelectDeviceGroupAction {
  type: typeof TOGGLE_SELECT_DEVICE_GROUP;
  deviceGroupId: string;
}

export interface ToggleSelectAllDeviceGroupsAction {
  type: typeof TOGGLE_SELECT_ALL_DEVICE_GROUPS;
  newValue: boolean;
}

export const fetchDeviceGroupsAction = (): FetchDeviceGroupsAction => ({
  type: FETCH_DEVICE_GROUPS,
});

export const resetDeviceGroups = (): DeviceGroupsResetAction => ({
  type: DEVICE_GROUPS_RESET,
});

export const deviceGroupsFetched = (deviceGroups: DeviceGroups): DeviceGroupsFetchedAction => ({
  type: DEVICE_GROUPS_FETCHED,
  deviceGroups: deviceGroups,
});

export const deviceGroupsFetchFailed = (): DeviceGroupsFetchFailedAction => ({
  type: DEVICE_GROUPS_FETCH_FAILED,
});

export const toggleSelectDeviceGroup = (deviceGroupId: string): ToggleSelectDeviceGroupAction => ({
  type: TOGGLE_SELECT_DEVICE_GROUP,
  deviceGroupId,
});

export const toggleSelectAllDeviceGroups = (newValue: boolean): ToggleSelectAllDeviceGroupsAction => ({
  type: TOGGLE_SELECT_ALL_DEVICE_GROUPS,
  newValue,
});
