import { Dispatch } from 'redux';
import { buildQueryString } from '../../../../utils/common';
import { authenticatedInstance, handleApiError } from '../../../../api/apiHelpers';
import { DEVICE_GROUPS_API } from '../../api-device-groups';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { deviceGroupFetched, deviceGroupFetchFailed, fetchDeviceGroupAction } from './fetch-device-group-action-types';


export const fetchDeviceGroup = (
  deviceGroupId: string,
  groupSelectUuid: string,
  appendUserAndDeviceInfo = false,
  appendAncestorGroups = true,
  appendChildGroups = false,
) => async (
  dispatch: Dispatch<any>,
): Promise<void> => {
  dispatch(fetchDeviceGroupAction(deviceGroupId, groupSelectUuid));

  const qs = buildQueryString({
    appendUserAndDeviceInfo,
    appendAncestorGroups,
    appendChildGroups
  });

  try {
    const url = `${ DEVICE_GROUPS_API }/${ deviceGroupId }?${ qs }`;
    const { data: deviceGroup } = await authenticatedInstance.get<DeviceGroup>(url);
    dispatch(deviceGroupFetched(deviceGroup, groupSelectUuid));
  } catch (error) {
    dispatch(deviceGroupFetchFailed(deviceGroupId, groupSelectUuid));
    handleApiError(error);
    throw error;
  }
};
