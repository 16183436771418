import {
  FETCH_GROUP_SELECT_MENU,
  FetchGroupSelectMenuTypes, GROUP_SELECT_MENU_FETCH_FAILED,
  GROUP_SELECT_MENU_FETCHED,
  GroupSelectMenu,
} from './fetch-group-select-menu/fetch-group-select-menu-action-types';
import {
  DEVICE_GROUP_FETCH_FAILED,
  DEVICE_GROUP_FETCHED,
  RESET_GROUP_SELECT,
  ResetGroupSelectAction, FETCH_DEVICE_GROUP,
  FetchDeviceGroupActionTypes
} from './fetch-selected-device-group/fetch-device-group-action-types';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';


type GroupSelectsState = {
  groupSelects: SingleGroupSelectState[];
};

const initialState: GroupSelectsState = {
  groupSelects: [],
};

export type SingleGroupSelectState = {
  groupSelectUuid: string;
  selectedDeviceGroup: DeviceGroup | null;
  isLoadingSelectedDeviceGroup: boolean;
  menu: GroupSelectMenu | null;
  isLoadingMenu: boolean;
};

export const getInitialSingleGroupSelectState = (groupSelectUuid: string): SingleGroupSelectState => ({
  groupSelectUuid: groupSelectUuid,
  selectedDeviceGroup: null,
  isLoadingSelectedDeviceGroup: false,
  menu: null,
  isLoadingMenu: false
});

export const groupSelectsReducer = (
  state: GroupSelectsState = initialState,
  action: FetchGroupSelectMenuTypes | ResetGroupSelectAction | FetchDeviceGroupActionTypes,
): GroupSelectsState => {
  switch (action.type) {
    case FETCH_GROUP_SELECT_MENU:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: true }
        )
      };

    case GROUP_SELECT_MENU_FETCHED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: false, menu: action.groupSelectMenu }
        )
      };

    case GROUP_SELECT_MENU_FETCH_FAILED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingMenu: false }
        )
      };

    case FETCH_DEVICE_GROUP:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingSelectedDeviceGroup: true }
        )
      };

    case DEVICE_GROUP_FETCHED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { selectedDeviceGroup: action.deviceGroup, isLoadingSelectedDeviceGroup: false }
        )
      };

    case DEVICE_GROUP_FETCH_FAILED:
      return {
        ...state,
        groupSelects: updateGroupSelect(state.groupSelects, action.groupSelectUuid,
          { isLoadingSelectedDeviceGroup: false }
        )
      };

    case RESET_GROUP_SELECT:
      return {
        ...state,
        groupSelects: state.groupSelects.filter(groupSelect =>
          (groupSelect.groupSelectUuid !== action.groupSelectUuid))
      };

    default:
      return state;
  }
};

const updateGroupSelect = (
  groupSelects: SingleGroupSelectState[],
  groupSelectUuid: string,
  update: Partial<SingleGroupSelectState>
): SingleGroupSelectState[] => {
  const existGroupSelectState = groupSelects.some(
    groupSelect => groupSelect.groupSelectUuid === groupSelectUuid);
  if (!existGroupSelectState) {
    groupSelects.push(getInitialSingleGroupSelectState(groupSelectUuid));
  }

  return groupSelects.map(groupSelect => {
    if (groupSelect.groupSelectUuid === groupSelectUuid) {
      return { ...groupSelect, ...update };
    }

    return groupSelect;
  });
};