import React, { useEffect, useState } from 'react';
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-localize-redux';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { AppState } from '../../state/reducers/rootReducer';
import { SetFieldValue } from '../../state/types';
import { OutsideClickHandler } from '../OutsideClickHandler';
import RenderOnCondition from '../RenderOnCondition';
import TableLoadingIcon from './TableLoadingIcon';
import Portal from './Portal';
import CustomCheckbox from './CustomCheckbox';
import classNames from 'classnames';
import { GROUP_PATH_DELIMITER } from '../../utils/device-group-path-helper';
import { ExpandIcon } from '../FilterBar/select/components/ExpandIcon';
import { VIRTUAL_ROOT_DEVICE_GROUP_ID } from '@wiot/shared-domain/domain/device-tree/virtual-root-device-group-id';
import { includesCaseInsensitive } from '../../utils/string';
import { fetchDeviceGroup } from '../../state/device-group/group-select/fetch-selected-device-group/fetchDeviceGroupActionCreator';

import useTranslation from '../../hooks/useTranslation';
import { resetGroupSelect } from '../../state/device-group/group-select/fetch-selected-device-group/fetch-device-group-action-types';
import { v4 as uuidV4 } from 'uuid';
import { selectGroupSelectStateByUuid } from '../../state/device-group/group-select/selectGroupSelectStateByUuid';
import {
  fetchMenuWithTopLevelGroups, fetchMenuWithChildrenOfGivenGroup,
  fetchMenuWithGivenGroupAmongItsSiblings
} from '../../state/device-group/group-select/fetch-group-select-menu/fetchMenuActionCreators';


export interface GroupSelectProps {
  onDeviceGroupChange?: (
    selectedGroup: DeviceGroup | string | undefined,
    setFieldValue: SetFieldValue,
    userRolesIndex?: number,
  ) => void;
  returnIdOverDeviceGroupOnDeviceGroupChange?: boolean;
  setFieldValue: SetFieldValue;
  preSelectedDeviceGroupId: string;
  rootIdOfRestrictedSubTree?: string;
  userRolesIndex?: number;
  customLabelTranslationId?: string;
  maxHeight?: number;
  error?: string;
  touched?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  targetId?: string;
  required?: boolean;
  dropDownWrapperClassNames?: string;
  isClearable?: boolean;
}

const GroupSelect = ({
  onDeviceGroupChange,
  returnIdOverDeviceGroupOnDeviceGroupChange,
  setFieldValue,
  preSelectedDeviceGroupId,
  rootIdOfRestrictedSubTree,
  customLabelTranslationId,
  maxHeight,
  userRolesIndex,
  error,
  touched,
  disabled,
  hideLabel,
  targetId = '',
  required = false,
  dropDownWrapperClassNames,
  isClearable = true,
}: GroupSelectProps) => {
  const groupSelectUuid = React.useRef<string>(uuidV4()).current;

  const dispatch = useDispatch();
  const translate = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isReadonlyVirtualRoot, setIsReadonlyVirtualRoot] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const reduxZindex = useSelector((state: AppState) => state.modalZindex);
  const groupSelectState = useSelector((state: AppState) => selectGroupSelectStateByUuid(state, groupSelectUuid));
  const selectedDeviceGroup = groupSelectState.selectedDeviceGroup;
  const menu = groupSelectState.menu;
  const isLoading = groupSelectState.isLoadingSelectedDeviceGroup || groupSelectState.isLoadingMenu;

  const toggleDropdownOpen = () => {
    if (!disabled && !isReadonlyVirtualRoot) {
      setIsExpanded(!isExpanded);
    }
  };

  useEffect(() => {
    if (preSelectedDeviceGroupId === VIRTUAL_ROOT_DEVICE_GROUP_ID) {
      setIsReadonlyVirtualRoot(true);
      return;
    }

    if (preSelectedDeviceGroupId) {
      dispatch(fetchDeviceGroup(preSelectedDeviceGroupId, groupSelectUuid));
      dispatch(fetchMenuWithGivenGroupAmongItsSiblings(preSelectedDeviceGroupId, groupSelectUuid));
    } else {
      dispatch(resetGroupSelect(groupSelectUuid));
      dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetGroupSelect(groupSelectUuid));
    };
  }, []);

  const onCheckboxClick = (
    event: React.MouseEvent<HTMLInputElement>,
    group: DeviceGroup,
  ) => {
    event.stopPropagation();
    setIsExpanded(false);
    dispatch(fetchDeviceGroup(group.id!, groupSelectUuid));
    const deviceGroupOrId: DeviceGroup | string = returnIdOverDeviceGroupOnDeviceGroupChange ? group.id! : group;
    onDeviceGroupChange && onDeviceGroupChange(deviceGroupOrId, setFieldValue, userRolesIndex);
  };

  const clearOptionSelect = () => {
    dispatch(resetGroupSelect(groupSelectUuid));
    dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
    onDeviceGroupChange && onDeviceGroupChange(undefined, setFieldValue, userRolesIndex);
  };

  const handleStepInto = async (group: DeviceGroup) => {
    const deviceGroupId = group.id!;
    if (!group.hasChildren || !isAllowedToSelect(deviceGroupId)) {
      return;
    }

    dispatch(fetchMenuWithChildrenOfGivenGroup(deviceGroupId, groupSelectUuid));
    setSearchTerm('');
  };


  const navigateToDeviceGroup = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    deviceGroup: DeviceGroup,
  ) => {
    event.preventDefault();

    if (deviceGroup.id === VIRTUAL_ROOT_DEVICE_GROUP_ID) {
      dispatch(fetchMenuWithTopLevelGroups(groupSelectUuid));
    } else {
      dispatch(fetchMenuWithChildrenOfGivenGroup(deviceGroup.id!, groupSelectUuid));
    }
  };

  const getSelectedGroupPath = () => {
    if (!selectedDeviceGroup) {
      return <Translate id="choose-one"/>;
    }

    const ancestorsNames = (selectedDeviceGroup.ancestors || []).map((ancestor) => ancestor.name);
    const pathGroupNames = [...ancestorsNames, selectedDeviceGroup.name || ''];

    return pathGroupNames.join(GROUP_PATH_DELIMITER);
  };

  const getMenuBreadCrumb = () => {
    return menu?.pathGroups.map((group, index) => {
      const isLastItem = index === menu?.pathGroups.length - 1;
      if (!isLastItem) {
        return (
          <a key={ index } href="#" onClick={ (e) => navigateToDeviceGroup(e, group) }>
            { ' ' }
            { index !== 0 && '›' } { group.name }
          </a>
        );
      }
      return (
        <span key={ index }>
          { ' ' }
          { index !== 0 && '›' } { group.name }
        </span>
      );
    });
  };

  const getSearchPlaceHolder = () => {
    if (!menu || !menu.deviceGroupName) {
      return '';
    }

    return `🔍 ${ translate('search-in', { groupName: menu.deviceGroupName, })?.toString() }`;
  };

  const getFilteredDeviceGroups = (): DeviceGroup[] => {
    if (!menu) {
      return [];
    }

    if (!searchTerm) {
      return menu.options;
    }

    return menu.options.filter(
      (group: DeviceGroup) =>
        group && includesCaseInsensitive(group.name, searchTerm)
    );
  };

  const isAllowedToSelect = (deviceGroupId: string) => {
    const isRestrictedDeviceGroup = !!rootIdOfRestrictedSubTree && rootIdOfRestrictedSubTree === deviceGroupId;
    return !isRestrictedDeviceGroup;
  };

  const getRenderedOptions = () => {
    return getFilteredDeviceGroups().map((group: DeviceGroup, index) => {
      const isAllowedToSelectGivenGroup: boolean = isAllowedToSelect(group.id!);
      const isCheckBoxVisible = isAllowedToSelectGivenGroup;
      const isChildrenLinkVisible = isAllowedToSelectGivenGroup && group.hasChildren;
      return (
        <div key={ group.id! + index } className="multiselect_option_wrapper">
          <div className="multiselect_option_content">
            <div className="multiselect_option_checkbox">
              <RenderOnCondition condition={ isCheckBoxVisible }>
                <CustomCheckbox
                  readOnly
                  onClick={ (event: React.MouseEvent<HTMLInputElement>) => {
                    onCheckboxClick(event, group);
                  } }
                  checked={ selectedDeviceGroup?.id === group.id }
                />
              </RenderOnCondition>
            </div>
            <div className="multiselect_option_label" onClick={ () => handleStepInto(group) }>
              { group.name }
              <div className="group-select__dropdown-indicator">
                { isChildrenLinkVisible &&
                    <FontAwesomeIcon className="group-select__dropdown-icon" icon={ faAngleRight }/>
                }
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const getGroupSelectPosition = () => {
    const selectEl = document.getElementById(targetId);
    const selectBoundary = selectEl?.getBoundingClientRect();
    return {
      top: (selectBoundary?.bottom || 0) + window.pageYOffset,
      right: document.body.clientWidth - (selectBoundary?.right || 0) + window.pageXOffset,
      zIndex: reduxZindex?.zIndex + 1,
    };
  };

  return (
    <div className="form__label">
      <RenderOnCondition condition={ !hideLabel }>
        <label className={ required ? 'required' : '' }>
          <Translate id={ customLabelTranslationId || 'choose-group' }/>
        </label>
      </RenderOnCondition>
      <div className="group-select">
        <div
          id={ targetId }
          className={ `
          group-select__control
          ${ isExpanded ? 'group-select__control--menu-is-open' : '' } 
          ${ disabled || isReadonlyVirtualRoot ? 'group-select-disabled' : '' }
        ` }
          tabIndex={ 0 }
        >
          <div className="group-select__label"
               onClick={ () => !isLoading && toggleDropdownOpen() }>
            <RenderOnCondition condition={ isLoading }>
              <TableLoadingIcon groupLoading size={ 30 }/>
            </RenderOnCondition>
            <RenderOnCondition condition={ !isLoading }>
              <span>{ getSelectedGroupPath() }</span>
            </RenderOnCondition>
          </div>

          <div className="group-select__indicator-container">
            <RenderOnCondition condition={ !disabled && !isReadonlyVirtualRoot }>
              <div
                className="group-select__dropdown-indicator"
                onClick={ () => !isLoading && toggleDropdownOpen() }
              >
                <ExpandIcon className="group-select__dropdown-icon" isExpanded={ isExpanded }/>
              </div>
            </RenderOnCondition>
            <RenderOnCondition condition={ isClearable }>
              <div
                className="fa-pull-right group-select__clear-indicator"
                onClick={ () => !isLoading && clearOptionSelect() }
              >
                <FontAwesomeIcon className="group-select__clear-icon" icon={ faTimes }/>
              </div>
            </RenderOnCondition>
          </div>
        </div>

        { isExpanded && !disabled && (
          <>
            <OutsideClickHandler onClickOutside={ () => setIsExpanded(false) }>
              <Portal>
                <div
                  style={ getGroupSelectPosition() }
                  className={ classNames('multiselect_dropdown_wrapper', dropDownWrapperClassNames || 'multiselect_group-select') }
                >
                  <div className="multiselect_breadcrumb">
                    <span>{ getMenuBreadCrumb() }</span>
                  </div>
                  <RenderOnCondition condition={ !!menu?.deviceGroupId }>
                    <Form.Control
                      type="text"
                      value={ searchTerm }
                      placeholder={ getSearchPlaceHolder() }
                      onChange={ (event: React.FormEvent<any>) => (setSearchTerm(event.currentTarget.value)) }
                    />
                  </RenderOnCondition>
                  <div className="multiselect_option_group-select" style={ { maxHeight: maxHeight || '' } }>
                    <>{ getRenderedOptions() }</>
                  </div>
                </div>
              </Portal>
            </OutsideClickHandler>
          </>
        ) }

      </div>
      { !!error && touched && <div className="input-error">{ error }</div> }
    </div>
  );
};

export default GroupSelect;
