import { Form, Formik, FormikProps, getIn } from 'formik';
import * as React from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Yup from 'yup';
import _ from 'lodash';
import { Col, Image, Nav, Row, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { ValueType } from 'react-select/src/types';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
import {
  Device,
  DeviceCreation,
  DeviceMetadata,
  DeviceMetadataFields,
  ICommunicationOptions,
  ProtocolType,
} from '@wiot/shared-domain/models/device/device';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { RadioKey } from '@wiot/shared-domain/models/radio-key/radio-key';
import { DeviceTypeWithPermission } from '@wiot/shared-domain/models/device-types/device-types';
import { IDeviceTypeProfile } from '@wiot/shared-domain/models/device-type-profile/device-type-profile';
import { AggregatedPermission, IDeviceRole, IMessageRole } from '@wiot/shared-domain/models/role/role';
import Portal from '../../../components/shared/Portal';
import {
  addDeviceImages,
  addDeviceToDB,
  appendFilesToFormData,
  deleteDeviceImage,
  fetchDeviceFromDB,
  fetchDeviceTypesForDeviceGroupFromDB,
  fetchManufacturersFromDB,
  getDeviceImages,
  getDeviceTypeProfiles,
  updateDeviceInDB,
} from '../../../api/apiHelpers';
import ModalHeader from '../../../components/Modal/ModalHeader';
import { CustomFieldInput } from '../../../components/Table/CustomFieldInput';
import CustomFieldSelect from '../../../components/Table/CustomFieldSelect';
import CustomImageFileInput from '../../../components/Table/CustomImageFileInput';
import ConfirmModal, { ConfirmationVariant } from '../../../components/Modal/ConfirmModal';
import Gallery from '../../../components/Gallery/Gallery';
import OcrWizard from '../../../components/Ocr/OcrWizard';
import { AppState } from '../../../state/reducers/rootReducer';
import { DeviceGroupExtended, DispatchActionTypes, SetFieldValue } from '../../../state/types';
import GroupSelect from '../../../components/shared/GroupSelect';
import {
  deviceDescriptionFields,
  deviceInfoFields,
  MetadataFieldColumnSize,
  radioInfoFields,
  radioTypeOptionsByMBusDeviceTypeId,
} from '../../../config/device-meta-data';
import CustomAutoSuggest from '../../../components/Table/CustomAutoSuggest';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import { isTableLoading } from '../../../state/table/isTableLoadingAction';
import { getDeviceTypeIconFullPath, getTranslationValueInCurrentLanguage, hasPermission } from '../../../utils/common';
import HasPermission from '../../../components/HasPermission';
import RenderOnCondition from '../../../components/RenderOnCondition';
import Desktop from '../../../components/Responsive/Desktop';
import Mobile from '../../../components/Responsive/Mobile';
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import CustomGeoInput from '../../../components/Table/CustomGeoInput';
import AsyncRadioKeySelect from '../../../components/shared/AsyncRadioKeySelect';
import { getRandomModalOffset } from '../../../utils/dialog';
import { getTranslationIdFromProtocolType } from '../../../config/device-modal-translation-ids';
import { getValidationSchema } from './validationSchema';
import {
  getProtocolType,
  getRadioType,
  getSelectedMetaDataValue,
  isDescriptionTabVisible,
  isRadioInfoTabVisible,
  isSelectRadioTypeDisabled,
  updateDeviceMetadataFieldValue,
} from './metaDataUtilityFunctions';
import { DeviceFields } from '@wiot/shared-domain/models/device/device-fields';
import { filterOutSensitiveDataFromDevice } from '../../../components/Feedback/filter-sensitive-data';
import { FeedbackAttachment } from '../../../components/Feedback/feedback';
import { ModalTabEventKeys } from '../ModalTabEventKeys';
import { MBusDeviceType } from '@wiot/shared-domain/models/device-types/m-bus-device-types';
import { SelectableOption } from '../../../components/FilterBar/select/selectable-option';
import { SelectWrapperWithFormik } from '../../../components/FilterBar/select/SelectWrapperWithFormik';
import { Tooltip } from '../../../components/shared/Tooltip';
import { PanelLock } from './PanelLock';

export interface DeviceActionModalProps extends LocalizeContextProps {
  closeAddAndUpdateModal: (
    event?: React.MouseEvent<Element, MouseEvent> | KeyboardEvent,
    newDeviceId?: string,
  ) => void;
  title: string;
  showDeleteButton: boolean;
  addUnit: boolean;
  duplicate?: boolean;
  selectedDeviceGroup?: DeviceGroup;
  id?: string;
  setIsTableLoading: (loading: boolean) => void;
  devices: Device[];
  removeUnit?: (id: string) => void;
  refreshTreeData?: () => void;
  refreshDevices?: () => void;
  oldDeviceId?: string;
  isOffline: boolean;
  disableGroupSelect?: boolean;
  getDeviceInfo?: (deviceId: string, setActiveTabKey?: React.Dispatch<React.SetStateAction<ModalTabEventKeys>>) => void;
  isKeyManagerModeEnabled: boolean;
  permission?: AggregatedPermission;
}

export interface IDeviceFormData {
  name: string;
  deviceId: string;
  deviceGroupId: null | string;
  radioKey?: RadioKey;
  deviceTypeId: string;
  consumptionEvaluationFactor: string;
  blacklisted: boolean;
  manufacturerFlagId: string;
  deviceMetadata?: DeviceMetadata;
  protocolType?: ProtocolType;
  oldDeviceId?: string;
  latitude?: string | number;
  longitude?: string | number;
  notes?: string;
  version?: string;
  communicationOptions?: ICommunicationOptions;
}

export interface DeviceActionModalState {
  availableDeviceTypes: DeviceTypeWithPermission[];
  deviceFormData: IDeviceFormData;
  deviceTypeProfile?: IDeviceTypeProfile;
  isDeleteModalVisible: boolean;
  isDeviceGroupRemovalModalVisible: boolean;
  images: (string | File)[];
  imagesToRemove: (string | File)[];
  isOcrLoading: boolean;
  manufacturerOptions: SelectableOption[];
  showOcrWizard: boolean;
  ocrFoundData: string[];
  showGallery: boolean;
  currentFile: string;
  currentImageIndex: number;
  isLoading: boolean;
  deviceRole?: IDeviceRole;
  messageRole?: IMessageRole;
  randomModalOffset?: { marginTop: number; marginRight: number };
  showRightOverflowIcon: boolean;
  showLeftOverflowIcon: boolean;
  validationErrorMessages: Map<string, string | undefined>;
  deviceGroupIdBeforeUpdate: null | string;
}

class DeviceActionModal extends React.Component<DeviceActionModalProps, DeviceActionModalState> {
  modalUid = uuidv4();

  constructor(props: DeviceActionModalProps) {
    super(props);

    const { selectedDeviceGroup, oldDeviceId, permission } = this.props;

    this.state = {
      availableDeviceTypes: [],
      deviceFormData: {
        name: '',
        deviceId: '',
        deviceGroupId: (selectedDeviceGroup && selectedDeviceGroup.id) || null,
        radioKey: undefined,
        deviceTypeId: '',
        latitude: '',
        longitude: '',
        manufacturerFlagId: '',
        notes: '',
        version: '',
        consumptionEvaluationFactor: '',
        blacklisted: false,
        oldDeviceId,
        deviceMetadata: {
          deviceDescription: [],
          deviceInfo: [],
          radioInfo: [],
          metadata: [],
        },
        communicationOptions: {
          secondaryRadioKey: undefined,
          lora: {
            joinEui: undefined,
          },
        },
      },
      deviceTypeProfile: undefined,
      isLoading: true,
      isDeleteModalVisible: false,
      isDeviceGroupRemovalModalVisible: false,
      images: [],
      imagesToRemove: [],
      isOcrLoading: false,
      showOcrWizard: false,
      ocrFoundData: [ '' ],
      deviceRole: permission?.devices,
      messageRole: undefined,
      currentFile: '',
      showGallery: false,
      manufacturerOptions: [],
      currentImageIndex: 0,
      randomModalOffset: { marginTop: 0, marginRight: 0 },
      showRightOverflowIcon: false,
      showLeftOverflowIcon: false,
      validationErrorMessages: new Map<string, string | undefined>(),
      deviceGroupIdBeforeUpdate: selectedDeviceGroup?.id || null,
    };
  }

  public componentDidMount = async () => {
    const {
      addUnit,
      id,
      duplicate = false,
      selectedDeviceGroup,
    } = this.props;

    this.setState({ randomModalOffset: getRandomModalOffset() });
    const manufacturers = await fetchManufacturersFromDB();
    let manufacturerOptions: SelectableOption[] = [];
    if (Array.isArray(manufacturers) && manufacturers.length > 0) {
      manufacturerOptions = manufacturers.map((manufacturer) => ({
        label: `${ manufacturer.flagId } - ${ manufacturer.name }`,
        value: manufacturer.flagId,
      }));
      this.setState({ manufacturerOptions });
    }
    if ((!addUnit && id) || (addUnit && id && duplicate)) {
      const device: Device = await fetchDeviceFromDB(id);

      // TODO: Implement an offline device model if they really differ.
      // @ts-ignore
      const { permission, messageRole } = device;

      const images = await getDeviceImages(id);

      let deviceTypes: DeviceTypeWithPermission[] = [];
      if (device.deviceGroup?.id) {
        deviceTypes = await fetchDeviceTypesForDeviceGroupFromDB(device.deviceGroup?.id);
      }

      const { deviceType } = device;

      // get device profile for add reading component
      const deviceTypeProfiles = await getDeviceTypeProfiles(
        device.manufacturer ? device.manufacturer.flagId : '',
        deviceType?.id,
      );
      deviceTypeProfiles
      && deviceTypeProfiles.length > 0
      && this.setState({
        deviceTypeProfile: deviceTypeProfiles[0],
      });
      this.setState({
        images,
        deviceRole: permission,
        messageRole,
        availableDeviceTypes: deviceTypes.filter((dt) => dt.isActive),
        deviceFormData: DeviceActionModal.getFormDataFromDevice(device, duplicate),
        deviceGroupIdBeforeUpdate: device.deviceGroup?.id || null,
      });
    } else if (selectedDeviceGroup) {
      const deviceTypes = await fetchDeviceTypesForDeviceGroupFromDB(
        selectedDeviceGroup.id!,
      );
      this.setState((prevState) => ({
        deviceFormData: {
          ...prevState.deviceFormData,
          deviceGroupId: selectedDeviceGroup?.id || null,
        },
        availableDeviceTypes: deviceTypes.filter((dt) => dt.isActive),
        deviceGroupIdBeforeUpdate: selectedDeviceGroup?.id || null,
      }));
    }
    // finish loading component
    this.setState({ isLoading: false });
  };

  private static getFormDataFromDevice(device: Device, isCloneMode: boolean): IDeviceFormData {
    return {
      name: device.name || '',
      protocolType: device.protocolType,
      deviceMetadata: device.deviceMetadata,
      deviceId: (!isCloneMode && device.deviceId) ? device.deviceId : '',
      consumptionEvaluationFactor: String(device.consumption?.evaluationFactor || ''),
      oldDeviceId: device.oldDevice,
      blacklisted: !!device.blacklisted,
      deviceGroupId: device.deviceGroup?.id || null,
      radioKey: device.radioKey || '',
      deviceTypeId: device.deviceType?.id || '',
      manufacturerFlagId: device.manufacturer?.flagId || '',
      notes: device.notes,
      version: device.version,
      communicationOptions: device.communicationOptions,
    };
  }

  private handleDeviceTypeChange = (
    event: React.FormEvent<HTMLSelectElement>,
    setFieldValue: SetFieldValue,
  ) => {
    const selectedDeviceTypeId = event.currentTarget.value;
    const selectedDeviceType = this.state.availableDeviceTypes.find(
      (deviceType) => deviceType.id === selectedDeviceTypeId,
    );

    setFieldValue('deviceTypeId', selectedDeviceTypeId, false);
    setFieldValue('deviceMetadata', {
      deviceDescription: [],
      deviceInfo: [],
      radioInfo: [],
      metadata: [],
    });

    const newState = this.state;
    newState.deviceFormData.deviceTypeId = selectedDeviceTypeId;
    this.setState({ ...newState, deviceRole: selectedDeviceType?.devicePermission });
  };

  private handleDeviceGroupChange = async (
    value: undefined | DeviceGroupExtended | string,
    setFieldValue: SetFieldValue,
  ) => {
    if (value) {
      const deviceTypes = await fetchDeviceTypesForDeviceGroupFromDB(
        typeof value === 'string' ? value : value.id!,
      );
      this.setState({
        availableDeviceTypes: deviceTypes.filter((deviceType) => deviceType.isActive),
      });
      setFieldValue('deviceGroupId', value, false);
      return;
    }
    if (!this.props.isKeyManagerModeEnabled) {
      setFieldValue('deviceGroupId', null, false);
    }
  }

  private toggleDeleteModal = () => {
    this.setState((prevState) => ({
      isDeleteModalVisible: !prevState.isDeleteModalVisible,
    }));
  };

  private toggleGallery = () => {
    this.setState((prevState) => ({
      showGallery: !prevState.showGallery,
    }));
  };

  private toggleOcrWizard = () => {
    this.setState((prevState) => ({
      showOcrWizard: !prevState.showOcrWizard,
    }));
  };

  private onDropFiles = (images: any) => {
    this.setState({ images });
  };

  private onImagesRemoved = (imagesToRemove: (string | File)[]) => {
    this.setState((prevState) => ({
      imagesToRemove: [ ...prevState.imagesToRemove, ...imagesToRemove ],
    }));
  };

  private toggleOcrLoading = () => {
    this.setState((prevState) => ({ isOcrLoading: !prevState.isOcrLoading }));
  };

  private onGallerySlideClick = (index: number) => {
    this.setState({ currentImageIndex: index });
  };

  private setOcrFoundData = (foundData: string[]) => {
    this.setState({ ocrFoundData: foundData });
  };

  private setCurrentFile = (file: string) => {
    this.setState({ currentFile: file });
  };

  private getDeviceTypeById = (deviceTypeId: string): DeviceTypeWithPermission | undefined => {
    const { availableDeviceTypes } = this.state;
    return availableDeviceTypes.find((dt) => dt.id === deviceTypeId);
  };

  handleSubmitPreparation = async (formData: IDeviceFormData) => {
    const wasDeviceGroupRemoved = !!this.state.deviceGroupIdBeforeUpdate && !formData.deviceGroupId;
    if (wasDeviceGroupRemoved) {
      this.setState({
        isDeviceGroupRemovalModalVisible: true,
        deviceFormData: { ...formData, deviceGroupId: null }
      });
    } else {
      this.handleSubmit(formData);
    }
  }

  handleSubmit = async (formData: IDeviceFormData) => {
    const { setIsTableLoading } = this.props;
    try {
      setIsTableLoading(true);

      const { isOffline, duplicate, addUnit } = this.props;
      const { images, imagesToRemove } = this.state;

      // find device type object given selected device type
      const uploadedImages = _.filter(
        images,
        (image) => typeof image !== 'string');
      const deletedImages = _.filter(
        imagesToRemove,
        (image) => typeof image === 'string',
      );

      const imagesForm = appendFilesToFormData(new FormData(), uploadedImages);

      const device: DeviceCreation = this.getDeviceFromFormData(formData);

      if (duplicate) {
        device.id = undefined;
      }

      if (isOffline) {
        // TODO: Implement an offline device model if they really differ.
        // @ts-ignore
        device.images = uploadedImages;
      }

      const { device: savedDevice } = addUnit
        ? await addDeviceToDB(device)
        : await updateDeviceInDB(device);

      // upload images
      if (savedDevice && savedDevice.id) {
        if (!isOffline) {
          if (uploadedImages.length > 0) {
            await addDeviceImages(savedDevice.id, imagesForm);
          }
          if (deletedImages.length > 0) {
            // eslint-disable-next-line array-callback-return
            const deletePromises = deletedImages.map((image) => {
              if (typeof image === 'string') {
                const splitParts = image.split('/');
                const imageId = splitParts[splitParts.length - 1];
                return deleteDeviceImage(imageId);
              }
            });
            await Promise.all(deletePromises);
          }
        }

        const {
          translate,
          refreshDevices,
          refreshTreeData,
          getDeviceInfo,
          closeAddAndUpdateModal,
        } = this.props;
        if (refreshDevices) {
          refreshDevices();
        }
        if (refreshTreeData) {
          refreshTreeData();
        }
        if (getDeviceInfo) {
          getDeviceInfo(savedDevice.id);
        }
        if (closeAddAndUpdateModal) {
          closeAddAndUpdateModal(undefined, savedDevice.id);
        }
        if (addUnit) {
          toastr.success(
            translate('success').toString(),
            translate('add-device-success').toString(),
          );
        } else {
          toastr.success(
            translate('success').toString(),
            translate('edit-device-success').toString(),
          );
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsTableLoading(false);
    }
  };

  private getDeviceFromFormData = (formData: IDeviceFormData): Device => {
    const { id } = this.props;

    const protocolType = getProtocolType(formData.deviceMetadata);

    return {
      name: formData.name,
      // @ts-ignore
      manufacturer: { flagId: formData.manufacturerFlagId },
      deviceId: formData.deviceId.trim(),
      blacklisted: formData.blacklisted,
      protocolType,
      oldDevice: formData.oldDeviceId || null,
      deviceMetadata: formData.deviceMetadata,
      // @ts-ignore
      deviceType: { id: formData.deviceTypeId },
      consumption: { evaluationFactor: Number(formData.consumptionEvaluationFactor) },
      deviceGroup: formData.deviceGroupId,
      id: id || '',
      radioKey: formData.radioKey?.id || null,
      notes: formData.notes,
      version: formData.version,
      communicationOptions: {
        ...formData.communicationOptions,
        secondaryRadioKey: formData.communicationOptions?.secondaryRadioKey?.id,
      },
    };
  };

  private getMetadataSelectOptions = (key: string) => {
    const { manufacturerOptions } = this.state;
    switch (key) {
      case 'manufacturer':
        return manufacturerOptions;
      default:
        return [];
    }
  };

  private getMetadataRenderedFields = (
    metadataCategory: keyof DeviceMetadata,
    fields: any[],
    deviceMetadata: DeviceMetadata | undefined,
    setFieldValue: SetFieldValue,
    onChange: (
      metadataCategoryName: keyof DeviceMetadata,
      key: string,
      value: string | ValueType<SelectableOption>,
      metadata: DeviceMetadata | undefined,
      setFieldValue: SetFieldValue,
    ) => void,
    deviceType: DeviceTypeWithPermission | undefined = undefined,
  ) =>
    fields?.map((field: any) => {
      const currentValue = getSelectedMetaDataValue(
        metadataCategory,
        field.fieldName,
        deviceMetadata,
      );
      switch (field.component) {
        case 'input':
        case 'half_input':
          return (
            <Col
              key={ `${field.component}-${field.fieldName}` }
              lg={
                field.columnSize ||
                field.component === 'half_input'
                  ? MetadataFieldColumnSize.QUARTER
                  : MetadataFieldColumnSize.HALF
            }>
              <CustomFieldInput
                type={ field.type }
                translationId={ field.fieldName }
                fieldName={ field.fieldName }
                onChange={ (event: React.FormEvent<HTMLInputElement>) =>
                  onChange(
                    metadataCategory,
                    field.fieldName,
                    event.currentTarget.value,
                    deviceMetadata,
                    setFieldValue,
                  )}
                value={ currentValue }
                masked={ field.masked }
                validate={ () => field.validate?.(currentValue) || '' }
                step={ field.step }
              />
            </Col>
          );
        case 'select':
          return (
            <Col key={ `${field.component}-${field.fieldName}` } lg={ field.columnSize || MetadataFieldColumnSize.HALF }>
              <CustomFieldSelect
                fieldName={ field.fieldName }
                options={
                  field.options ? field.options : this.getMetadataSelectOptions(field.values)
                }
                translateOptions={ field.translateOptions }
                value={ currentValue }
                onChange={ (event: React.FormEvent<HTMLSelectElement>) =>
                  onChange(
                    metadataCategory,
                    field.fieldName,
                    event.currentTarget.value,
                    deviceMetadata,
                    setFieldValue,
                  )
                }
              />
            </Col>
          );
        case 'autosuggest':
        case 'half_autosuggest':
          return (
            <Col
              key={ `${field.component}-${field.fieldName}` }
              lg={
                field.columnSize ||
                field.component === 'half_autosuggest'
                  ? MetadataFieldColumnSize.QUARTER
                  : MetadataFieldColumnSize.HALF
              }
            >
              <CustomAutoSuggest
                name={ field.fieldName }
                selectOptionAutomatic={ field.fieldName === 'key' }
                options={
                  field.options ? field.options : this.getMetadataSelectOptions(field.values)
                }
                value={ currentValue }
                onChange={ (name: string, value: ValueType<SelectableOption>) => {
                  onChange(metadataCategory, field.fieldName, value, deviceMetadata, setFieldValue);
                } }
                translateOptions={ field.translateOptions }
                portalId={ `modal-${this.props.title}-${this.props.id}` }
              />
            </Col>
          );
        case 'react-select':
          return (
            <Col key={ `${field.component}-${field.fieldName}` } lg={ field.columnSize || MetadataFieldColumnSize.HALF }>
              <CustomFieldSelect
                translationId={field.fieldName}
                fieldName={field.fieldName}
                options={field.options ? field.options : this.getMetadataSelectOptions(field.values)}
                value={ currentValue }
                onChange={ (event: React.ChangeEvent<HTMLSelectElement>) => {
                  const target = (event?.currentTarget || event?.target);
                  if (target) {
                    onChange(metadataCategory, field.fieldName, target.value, deviceMetadata, setFieldValue);
                  }
                } }
                labelKey={ field.labelKey || 'label' }
                valueKey={ field.valueKey || 'value' }
                portalTargetId={ `modal-${this.props.title}-${this.props.id}` }
              />
            </Col>
          );
        case 'geo':
          return (
            <RenderOnCondition condition={ !this.props.isKeyManagerModeEnabled }>
              <CustomGeoInput
                key={ `${field.component}-${field.fieldName}` }
                deviceType={ deviceType }
                latitude={ getSelectedMetaDataValue(metadataCategory, 'latitude', deviceMetadata) }
                longitude={ getSelectedMetaDataValue(metadataCategory, 'longitude', deviceMetadata) }
                onChange={ (fieldName: 'latitude' | 'longitude', value: string) =>
                  onChange(metadataCategory, fieldName, value, deviceMetadata, setFieldValue)}
              />
            </RenderOnCondition>
          );
        default:
          return null;
      }
    });

  private getRadioTypeOptions = (deviceType?: DeviceTypeWithPermission): { name: string; id: string }[] => {
    if (!deviceType || !deviceType.mBusDeviceTypeId) {
      return [];
    }

    return radioTypeOptionsByMBusDeviceTypeId[deviceType.mBusDeviceTypeId];
  };

  private getCustomDeviceInfoFields = (
    deviceMetadata: DeviceMetadata | undefined,
    setFieldValue: SetFieldValue,
    deviceType: DeviceTypeWithPermission | undefined,
  ) => {
    const radioType = getRadioType(deviceMetadata?.deviceInfo);
    if (radioType) {
      const fieldsToRender = deviceInfoFields[radioType];
      return this.getMetadataRenderedFields(
        DeviceMetadataFields.deviceInfo,
        fieldsToRender,
        deviceMetadata,
        setFieldValue,
        updateDeviceMetadataFieldValue,
        deviceType,
      );
    }
    return null;
  };

  private getCustomDeviceDescriptionFields = (
    deviceMetadata: DeviceMetadata | undefined,
    setFieldValue: SetFieldValue,
  ) => {
    const radioType = getRadioType(deviceMetadata?.deviceInfo);
    if (radioType) {
      // @ts-ignore
      const fieldsToRender = deviceDescriptionFields[radioType];
      return (
        fieldsToRender && (
          <Row>
            { this.getMetadataRenderedFields(
              DeviceMetadataFields.deviceDescription,
              fieldsToRender,
              deviceMetadata,
              setFieldValue,
              updateDeviceMetadataFieldValue,
            ) }
          </Row>
        )
      );
    }
    return null;
  };

  private getCustomRadioInfoFields = (
    deviceMetadata: DeviceMetadata | undefined,
    setFieldValue: SetFieldValue,
  ) => {
    const radioType = getRadioType(deviceMetadata?.deviceInfo);
    if (radioType) {
      const fieldsToRender = radioInfoFields[radioType];
      return (
        fieldsToRender?.length > 0 && (
          <Row>
            { this.getMetadataRenderedFields(
              DeviceMetadataFields.radioInfo,
              fieldsToRender,
              deviceMetadata,
              setFieldValue,
              updateDeviceMetadataFieldValue,
            ) }
          </Row>
        )
      );
    }
    return null;
  };

  private handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const targetEl = e.currentTarget;
    this.setState({
      showRightOverflowIcon:
        targetEl.scrollWidth > targetEl.clientWidth
        && targetEl.scrollLeft < targetEl.scrollWidth - targetEl.clientWidth,
      showLeftOverflowIcon: targetEl.scrollWidth > targetEl.clientWidth && targetEl.scrollLeft > 0,
    });
  };

  private getPermissionKey = (section: string) => {
    const { addUnit } = this.props;
    return addUnit ? 'add' : `edit.${section}`;
  };

  private getAttachmentForFeedback = (): FeedbackAttachment | null => {
    const device = this.getDeviceFromFormData(this.state.deviceFormData);
    if (!device) {
      return null;
    }
    return {
      device: filterOutSensitiveDataFromDevice(device),
    };
  };

  public render() {
    const {
      images,
      deviceFormData,
      deviceTypeProfile,
      isLoading,
      manufacturerOptions,
      isDeleteModalVisible,
      isDeviceGroupRemovalModalVisible,
      showGallery,
      currentImageIndex,
      showOcrWizard,
      isOcrLoading,
      ocrFoundData,
      currentFile,
      deviceRole,
      messageRole,
      availableDeviceTypes,
      validationErrorMessages,
    } = this.state;
    const {
      id,
      title,
      closeAddAndUpdateModal,
      disableGroupSelect,
      showDeleteButton,
      addUnit,
      removeUnit,
      translate,
      duplicate,
      isKeyManagerModeEnabled,
    } = this.props;
    const { deviceTypeId, deviceId } = deviceFormData;
    const deviceType = this.getDeviceTypeById(deviceTypeId);

    const validateNumberFieldAndSetErrorMessage = (fieldName: string, value: any) => {
      const errorMessage = isNaN(value) ? translate('validation-error-invalid-number').toString() : undefined;
      const nextValidationErrorMessages = new Map(validationErrorMessages);
      nextValidationErrorMessages.set(fieldName, errorMessage);
      this.setState({
        ...this.state,
        validationErrorMessages: nextValidationErrorMessages,
      });
      return errorMessage;
    };

    return (
      <Portal>
        <div
          tabIndex={ 0 } // make it focusable
          style={ this.state.randomModalOffset }
          id={ `${title}-${this.modalUid}` }
          className="device-modal"
        >
          <ModalHeader
            isDevice={ true }
            titleTranslationId={ title }
            handleClose={ closeAddAndUpdateModal }
            targetId={ this.modalUid }
            titlePostfix={ deviceId }
            enableFeedbackSubmission={true}
            getFeedbackAttachment={ this.getAttachmentForFeedback }
          />
          <div className="device-modal__body" id={ `modal-${title}-${id}` }>
            <Formik
              initialValues={ deviceFormData }
              validationSchema={ Yup.object(getValidationSchema(translate)) }
              isInitialValid={ false }
              enableReinitialize
              onSubmit={ this.handleSubmitPreparation }
              render={ ({
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
              }: FormikProps<IDeviceFormData>) => {
                if (isLoading) {
                  return (
                    <div className="loading-container">
                      <LoadingIcon/>
                    </div>
                  );
                }

                const showConsumptionEvaluationFactorField = deviceType?.mBusDeviceTypeId === MBusDeviceType.HEAT_COST_ALLOCATOR;

                const isGroupSelectClearable = !isKeyManagerModeEnabled && deviceRole?.edit?.removeDeviceGroup;
                const isDeviceIdReadOnly = isKeyManagerModeEnabled || !addUnit;
                const isDeviceGroupPathReadOnly = disableGroupSelect || isKeyManagerModeEnabled;
                const isSecondaryRadioKeyVisible = getProtocolType(values.deviceMetadata) === ProtocolType.LORA_ABP_DEVICE && !isKeyManagerModeEnabled;

                const translationIdForDeviceNameField = getTranslationIdFromProtocolType(values, DeviceFields.DEVICE_NAME);
                const translationIdForDeviceIdField = getTranslationIdFromProtocolType(values, DeviceFields.DEVICE_ID);
                const translationIdForDeviceJoinEuiField = getTranslationIdFromProtocolType(values, DeviceFields.JOIN_EUI);
                const translationIdForDeviceVersionField = getTranslationIdFromProtocolType(values, DeviceFields.DEVICE_VERSION);
                const translationIdForRadioKeySelection = getTranslationIdFromProtocolType(values, DeviceFields.RADIO_KEY);
                const translationIdForSecondaryRadioKeySelection = getTranslationIdFromProtocolType(values, DeviceFields.SECONDARY_RADIO_KEY);

                return (
                  <Form
                    className="form"
                    onClick={ (event: React.MouseEvent) => event.stopPropagation() }
                  >
                    <div className="form__section">
                      <div className="form__row">
                        <GroupSelect
                          onDeviceGroupChange={ this.handleDeviceGroupChange }
                          setFieldValue={ setFieldValue }
                          preSelectedDeviceGroupId={ values.deviceGroupId || '' }
                          returnIdOverDeviceGroupOnDeviceGroupChange
                          error={ errors.deviceGroupId }
                          touched={ touched.deviceGroupId }
                          disabled={ isDeviceGroupPathReadOnly }
                          targetId={ `group-select-${title}-${id}` }
                          isClearable={ isGroupSelectClearable }
                        />
                      </div>
                    </div>
                    <RenderOnCondition condition={ !addUnit || values.deviceGroupId }>
                      <Tab.Container id="device-action-modal-tabs" defaultActiveKey={ ModalTabEventKeys.BASIC }>
                        <Nav variant="tabs" onScroll={ this.handleScroll }>
                          <RenderOnCondition condition={ this.state.showLeftOverflowIcon }>
                            <div className="overflow-left-icon-container">
                              <FontAwesomeIcon className="left-icon" icon={ faCaretLeft }/>
                            </div>
                          </RenderOnCondition>
                          <RenderOnCondition condition={ this.state.showRightOverflowIcon }>
                            <div className="overflow-right-icon-container">
                              <FontAwesomeIcon className="right-icon" icon={ faCaretRight }/>
                            </div>
                          </RenderOnCondition>

                          <Nav.Item>
                            <Nav.Link
                              eventKey={ ModalTabEventKeys.BASIC }
                              className={ _.isEmpty(errors) ? '' : 'color-danger' }
                            >
                              <Translate id="basic"/>
                            </Nav.Link>
                          </Nav.Item>

                          <HasPermission
                            permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.DESCRIPTION) }
                          >
                            <RenderOnCondition
                              condition={ isDescriptionTabVisible(
                                isKeyManagerModeEnabled,
                                values.deviceMetadata?.deviceInfo,
                                deviceType,
                              ) }
                            >
                              <Nav.Item>
                                <Nav.Link eventKey={ ModalTabEventKeys.DESCRIPTION }>
                                  <Translate id="device-description"/>
                                </Nav.Link>
                              </Nav.Item>
                            </RenderOnCondition>
                          </HasPermission>

                          <HasPermission
                            permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.RADIO_INFO) }
                          >
                            <RenderOnCondition
                              condition={ isRadioInfoTabVisible(isKeyManagerModeEnabled, values.deviceMetadata?.deviceInfo) }
                            >
                              <Nav.Item>
                                <Nav.Link eventKey={ ModalTabEventKeys.RADIO_INFO }>
                                  <Translate id="radioInfo"/>
                                </Nav.Link>
                              </Nav.Item>
                            </RenderOnCondition>
                          </HasPermission>

                          <HasPermission
                            permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.METADATA) }
                          >
                            <Nav.Item>
                              <Nav.Link eventKey={ ModalTabEventKeys.METADATA }>
                                <Translate id="metadata"/>
                              </Nav.Link>
                            </Nav.Item>
                          </HasPermission>

                          <HasPermission
                            permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.IMAGES) }
                          >
                            <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                              <Nav.Item>
                                <Nav.Link eventKey={ ModalTabEventKeys.IMAGES }>
                                  <Translate id="images"/>
                                </Nav.Link>
                              </Nav.Item>
                            </RenderOnCondition>
                          </HasPermission>
                        </Nav>

                        <Tab.Content>
                          <Tab.Pane eventKey={ ModalTabEventKeys.BASIC }>
                            <PanelLock isLocked={ !values.deviceGroupId } translationIdForPanelName="basic">
                              <Row>
                                <Col xs={ 12 }>
                                  <Row>
                                    <Col lg={ 3 } className="text-center">
                                      <Image
                                        className="thumbnail"
                                        src={ getDeviceTypeIconFullPath(deviceType) }
                                        alt="device type"
                                      />
                                    </Col>

                                    <Col lg={ 9 }>
                                      <Row>
                                        <Col lg={ 6 }>
                                          <CustomFieldSelect
                                            translationId="device-type"
                                            fieldName="deviceTypeId"
                                            options={ availableDeviceTypes.map((dt) => ({
                                              id: dt.id,
                                              name: getTranslationValueInCurrentLanguage(dt.name),
                                            })) }
                                            value={ deviceTypeId }
                                            error={ errors.deviceTypeId }
                                            touched={ touched.deviceTypeId }
                                            onChange={ (event: React.FormEvent<HTMLSelectElement>) => {
                                              this.handleDeviceTypeChange(event, setFieldValue);
                                            } }
                                            portalTargetId={ `modal-${ this.props.title }-${ this.props.id }` }
                                            readOnly={ isKeyManagerModeEnabled }
                                          />
                                        </Col>
                                        <HasPermission
                                          permissionObj={ deviceRole }
                                          permissionKey={ this.getPermissionKey(ModalTabEventKeys.BASIC) }
                                        >
                                          <Col lg={ 6 }>
                                            <CustomFieldSelect
                                              translationId={ DeviceFields.RADIO_TYPE }
                                              fieldName="radioType"
                                              options={ this.getRadioTypeOptions(deviceType) }
                                              translateOptions
                                              value={ getSelectedMetaDataValue(
                                                DeviceMetadataFields.deviceInfo,
                                                DeviceFields.RADIO_TYPE,
                                                values.deviceMetadata,
                                              ) }
                                              onChange={ (event: React.FormEvent<HTMLSelectElement>) =>
                                                updateDeviceMetadataFieldValue(
                                                  DeviceMetadataFields.deviceInfo,
                                                  DeviceFields.RADIO_TYPE,
                                                  event.currentTarget.value,
                                                  values.deviceMetadata,
                                                  setFieldValue,
                                                ) }
                                              readOnly={
                                                isSelectRadioTypeDisabled(values.deviceMetadata,
                                                  isKeyManagerModeEnabled)
                                              }
                                              portalTargetId={ `modal-${ this.props.title }-${ this.props.id }` }
                                            />
                                          </Col>
                                        </HasPermission>
                                    </Row>

                                    <HasPermission
                                      permissionObj={ deviceRole }
                                      permissionKey={ this.getPermissionKey(ModalTabEventKeys.BASIC) }
                                    >
                                      <Row>
                                        <Col lg={ 6 }>
                                          <CustomFieldInput
                                            translationId={translationIdForDeviceNameField }
                                            fieldName="name"
                                            value={ values.name || '' }
                                            readOnly={ isKeyManagerModeEnabled }
                                          />
                                        </Col>
                                        <Col lg={ 6 }>
                                          <CustomFieldInput
                                            translationId={ translationIdForDeviceIdField }
                                            fieldName={DeviceFields.DEVICE_ID}
                                            placeholder={ translate('found-on-device').toString() }

                                            value={ values.deviceId }
                                            error={ errors.deviceId }
                                            touched={ touched.deviceId }
                                            readOnly={ isDeviceIdReadOnly }
                                          />
                                        </Col>
                                      </Row>
                                      <RenderOnCondition
                                        condition={
                                          getProtocolType(
                                            values.deviceMetadata,
                                          ) === ProtocolType.LORA_OTAA_DEVICE
                                        }
                                      >
                                        <Row>
                                          <Col lg={ 6 }/>
                                          <Col lg={ 6 }>
                                            <CustomFieldInput
                                              translationId={ translationIdForDeviceJoinEuiField }
                                              fieldName="communicationOptions.lora.joinEui"
                                              placeholder={ translate('found-on-device').toString() }
                                              value={
                                                values.communicationOptions?.lora?.joinEui || ''
                                              }
                                              readOnly={ isKeyManagerModeEnabled }
                                            />
                                          </Col>
                                        </Row>
                                      </RenderOnCondition>
                                      <Row>
                                        <Col lg={ 6 }>
                                          <CustomFieldInput
                                            translationId={ translationIdForDeviceVersionField }
                                            fieldName="version"
                                            value={ values.version || '' }
                                            readOnly={ isKeyManagerModeEnabled }
                                          />
                                        </Col>

                                        <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                                          <Col lg={ 6 }>
                                            <AsyncRadioKeySelect
                                              translationId="select"
                                              label={ translationIdForRadioKeySelection }
                                              handleSelectionChange={ (radioKey) =>
                                                setFieldValue(DeviceFields.RADIO_KEY, radioKey) }
                                              selected={ values.radioKey }
                                              error={ errors.radioKey }
                                              touched={ touched.radioKey }
                                            />
                                          </Col>
                                        </RenderOnCondition>
                                      </Row>

                                      <RenderOnCondition
                                        condition={ isSecondaryRadioKeyVisible || showConsumptionEvaluationFactorField }>
                                        <Row>
                                          <Col lg={ 6 }>
                                            <RenderOnCondition condition={ showConsumptionEvaluationFactorField }>
                                              <CustomFieldInput
                                                translationId="evaluation-factor"
                                                fieldName="consumptionEvaluationFactor"
                                                placeholder={ translate('evaluation-factor-example-value').toString() }
                                                error={ validationErrorMessages.get('consumptionEvaluationFactor') }
                                                validate={ (value) => validateNumberFieldAndSetErrorMessage(
                                                  'consumptionEvaluationFactor',
                                                  value,
                                                ) }
                                                value={ values.consumptionEvaluationFactor }
                                              />
                                            </RenderOnCondition>
                                          </Col>

                                          <Col lg={ 6 }>
                                            <RenderOnCondition condition={ isSecondaryRadioKeyVisible }>
                                              <AsyncRadioKeySelect
                                                label={ translationIdForSecondaryRadioKeySelection }
                                                handleSelectionChange={ (secondaryRadioKey) =>
                                                  setFieldValue(
                                                    'communicationOptions.secondaryRadioKey',
                                                    secondaryRadioKey,
                                                  ) }
                                                selected={
                                                  values.communicationOptions?.secondaryRadioKey
                                                }
                                                error={ getIn(
                                                  errors,
                                                  'communicationOptions.secondaryRadioKey',
                                                ) }
                                                touched={ getIn(
                                                  touched,
                                                  'communicationOptions.secondaryRadioKey',
                                                ) }
                                              />
                                            </RenderOnCondition>
                                          </Col>
                                        </Row>
                                      </RenderOnCondition>
                                      <SelectWrapperWithFormik<SelectableOption>
                                        label="manufacturer"
                                        fieldName={ 'manufacturerFlagId' }
                                        listHeight={ 200 }
                                        options={ manufacturerOptions }
                                        value={ values.manufacturerFlagId }
                                        onChange={ setFieldValue }
                                        onBlur={ setFieldTouched }
                                        error={ errors.manufacturerFlagId }
                                        touched={ touched.manufacturerFlagId }
                                        valueKey="value"
                                        portalTargetId={ `modal-${ title }-${ id }` }
                                        hideLabel={ false }
                                        readOnly={ isKeyManagerModeEnabled }
                                        isMulti={ false }
                                      />
                                    </HasPermission>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </PanelLock>
                        </Tab.Pane>

                        <HasPermission
                          permissionObj={ deviceRole }
                          permissionKey={ this.getPermissionKey(ModalTabEventKeys.DESCRIPTION) }
                        >
                          <Tab.Pane eventKey={ ModalTabEventKeys.DESCRIPTION }>
                            <PanelLock isLocked={ !values.deviceGroupId } translationIdForPanelName="device-description">
                              { this.getCustomDeviceDescriptionFields(
                                  values.deviceMetadata,
                                  setFieldValue,
                                ) }
                            </PanelLock>
                          </Tab.Pane>
                        </HasPermission>

                        <HasPermission
                          permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.RADIO_INFO) }
                          >
                          <Tab.Pane eventKey={ ModalTabEventKeys.RADIO_INFO }>
                            <PanelLock isLocked={ !values.deviceGroupId } translationIdForPanelName="radioInfo">
                              { this.getCustomRadioInfoFields(values.deviceMetadata, setFieldValue) }
                            </PanelLock>
                          </Tab.Pane>
                        </HasPermission>

                        <HasPermission
                          permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.METADATA) }
                          >
                          <Tab.Pane eventKey={ ModalTabEventKeys.METADATA }>
                              <PanelLock isLocked={ !values.deviceGroupId } translationIdForPanelName="metadata">
                                <div className="form__row">
                                  <CustomFieldInput
                                    translationId="notes"
                                    fieldName="notes"
                                    placeholder={ translate('for-notes').toString() }
                                    value={ values.notes || '' }
                                  />
                                </div>
                                <Row>
                                  { this.getCustomDeviceInfoFields(
                                    values.deviceMetadata,
                                    setFieldValue,
                                    deviceType,
                                  ) }
                                </Row>
                              </PanelLock>
                            </Tab.Pane>
                          </HasPermission>

                          <HasPermission
                            permissionObj={ deviceRole }
                            permissionKey={ this.getPermissionKey(ModalTabEventKeys.IMAGES) }
                          >
                            <Tab.Pane eventKey={ ModalTabEventKeys.IMAGES }>
                              <PanelLock isLocked={ !values.deviceGroupId } translationIdForPanelName="images">
                                <div className="form__row">
                                  <CustomImageFileInput
                                    onFilesUpdated={ this.onDropFiles }
                                    onFilesRemoved={ this.onImagesRemoved }
                                    files={ images }
                                    toggleGallery={ this.toggleGallery }
                                    toggleOcrLoading={ this.toggleOcrLoading }
                                    toggleOcrWizard={ this.toggleOcrWizard }
                                    isOcrLoading={ isOcrLoading }
                                    setCurrentFile={ this.setCurrentFile }
                                    setOcrFoundData={ this.setOcrFoundData }
                                  />
                                </div>
                              </PanelLock>
                            </Tab.Pane>
                          </HasPermission>

                          <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                            <Mobile>
                              <div className="form__section">
                                <div
                                  className="form__blacklist"
                                  data-tip="dev-action-modal-blacklist"
                                  data-for="dev-action-modal-blacklist"
                                >
                                  <label className="form__label">
                                    <Translate id="blacklist"/>
                                    <CustomCheckbox
                                      readOnly
                                      checked={ values.blacklisted }
                                      onClick={ () =>
                                        setFieldValue('blacklisted', !values.blacklisted) }
                                    />
                                  </label>
                                </div>
                                <Tooltip
                                  id="dev-action-modal-blacklist"
                                  place="top"
                                >
                                  <Translate id="device-action-blacklist-description"/>
                                </Tooltip>
                              </div>
                            </Mobile>
                          </RenderOnCondition>

                          <div className="form__section last">
                            <div className="form__row space-between ai">
                              <div className="form__row--left">
                                <HasPermission permissionObj={ deviceRole } permissionKey="remove">
                                  <RenderOnCondition condition={ showDeleteButton }>
                                    <button
                                      type="button"
                                      className="form__button--delete"
                                      onClick={ this.toggleDeleteModal }
                                    >
                                      <Desktop>
                                        <Translate id="remove-device"/>
                                      </Desktop>
                                      <Mobile>
                                        <Translate id="remove"/>
                                      </Mobile>
                                    </button>
                                  </RenderOnCondition>
                                </HasPermission>

                                <RenderOnCondition condition={ !isKeyManagerModeEnabled }>
                                  <Desktop>
                                    <>
                                      <div
                                        className={ classNames('form__blacklist', {
                                          'ml-2':
                                            showDeleteButton && hasPermission(deviceRole, 'remove'),
                                        }) }
                                        data-tip="dev-action-modal-blacklist"
                                        data-for="dev-action-modal-blacklist"
                                      >
                                        <label className="form__label">
                                          <Translate id="blacklist"/>
                                          <CustomCheckbox
                                            checked={ values.blacklisted }
                                            onChange={ () =>
                                              setFieldValue('blacklisted', !values.blacklisted) }
                                          />
                                        </label>
                                      </div>
                                      <Tooltip
                                        id="dev-action-modal-blacklist"
                                        offset={ { right: 10 } }
                                        place="right"
                                      >
                                        <Translate id="device-action-blacklist-description"/>
                                      </Tooltip>
                                    </>
                                  </Desktop>
                                </RenderOnCondition>
                              </div>

                              <div className="form__row--right">
                                <button
                                  type="button"
                                  className="form__button--cancel"
                                  onClick={ closeAddAndUpdateModal }
                                >
                                  <Translate id="cancel"/>
                                </button>
                                <button
                                  className="form__button--blue background-color-main text-color-white border-color-main"
                                  type="submit"
                                >
                                  <Desktop>
                                    <Translate id={ addUnit ? 'add-device' : 'save-device' }/>
                                  </Desktop>
                                  <Mobile>
                                    <Translate id={ addUnit ? 'add' : 'save' }/>
                                  </Mobile>
                                </button>
                              </div>
                            </div>
                          </div>

                          { showOcrWizard && (
                            <OcrWizard
                              isOcrLoading={ isOcrLoading }
                              values={ values }
                              setFieldValue={ setFieldValue }
                              ocrFoundData={ ocrFoundData }
                              toggleOcrWizard={ this.toggleOcrWizard }
                              currentFile={ currentFile }
                            />
                          ) }
                        </Tab.Content>
                      </Tab.Container>
                    </RenderOnCondition>
                  </Form>
                );}
              }
            />

            { isDeviceGroupRemovalModalVisible && (
              <Portal>
                <ConfirmModal
                  show={ isDeviceGroupRemovalModalVisible }
                  actionConfirmed={ () => this.handleSubmit(this.state.deviceFormData) }
                  modalCloseRequested={ () => this.setState({ isDeviceGroupRemovalModalVisible: false }) }
                  translationIdOfElementType="confirm-to-remove-property-device-group-type"
                  translationIdOfAdditionalInfo={
                    this.props.permission?.deviceManager?.viewUnassigned
                      ? 'confirm-to-remove-property-device-group'
                      : 'confirm-to-remove-property-device-group-not-visible'
                  }
                  confirmationVariant={ ConfirmationVariant.REMOVE_DEVICE_GROUP }
                />
              </Portal>
            ) }

            { id && isDeleteModalVisible && (
              <ConfirmModal
                modalCloseRequested={ this.toggleDeleteModal }
                actionConfirmed={ async () => {
                  removeUnit && removeUnit(id);
                } }
                translationIdOfElementType="device"
                confirmationVariant={ ConfirmationVariant.DELETE }
              />
            ) }

            { showGallery && (
              <Gallery
                files={ images }
                currentIndex={ currentImageIndex }
                onGallerySlideClick={ this.onGallerySlideClick }
                toggleGallery={ this.toggleGallery }
              />
            ) }
          </div>
        </div>
      </Portal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  devices: state.devices,
  isOffline: state.isOffline,
  isKeyManagerModeEnabled: !!state.siteSettings.isKeyManagerModeEnabled,
  permission: state.currentUser.permission,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  setIsTableLoading: (loading: boolean) => dispatch(isTableLoading(loading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DeviceActionModal));
