import * as React from 'react';
import { DeviceGroup } from '@wiot/shared-domain/models/device-group/device-group';
import { IColumnObject } from '@wiot/shared-domain/models/device/device';
import LoadingIcon from '../../../components/shared/LoadingIcon';
import TableDataHeader from '../../../components/Table/TableDataHeader';
import NoResultsInfo from '../../../components/Table/NoResultsInfo';
import RenderOnCondition from '../../../components/RenderOnCondition';
import TableDataRow from './TableDataRow';
import { DEVICE_GROUPS_TABLE_HEADERS as TABLE_HEADERS } from '../../../constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../state/reducers/rootReducer';
import {
  toggleSelectAllDeviceGroups,
  toggleSelectDeviceGroup
} from '../../../state/device-group/paginated-device-groups/paginated-device-group-action-types';

export interface TableDataProps {
  removeUnit: (id: string) => Promise<void>;
}

const TableData = (props:TableDataProps) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState<IColumnObject[]>([]);
  const deviceGroups = useSelector((state: AppState) => state.paginatedDeviceGroups.currentPageItems);
  const selectedItems = useSelector((state: AppState) => state.paginatedDeviceGroups.selectedItems);
  const isSelectAllChecked  = (deviceGroups.length === selectedItems.length) && !!deviceGroups.length;
  const isLoadingDeviceGroups = useSelector((state: AppState) => state.paginatedDeviceGroups.isLoadingDeviceGroups);

  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const showResults = (deviceGroups && deviceGroups.length && !isLoadingDeviceGroups);

  useEffect(() => {
    setColumns(getTableHeaders());
  }, []);

  const getTableHeaders = ():IColumnObject[] => {
    let headerNames = TABLE_HEADERS;
    if (isKeyManagerModeEnabled) {
      headerNames = TABLE_HEADERS.filter(
        (headerName) => headerName !== 'abbreviation'
      );
    }

    return headerNames.map((headerName) => ({
      name: headerName,
      visible: true,
      sortable: true,
    }));
  };

    if (isLoadingDeviceGroups) {
      return <LoadingIcon/>
    }

    return (
      <div className="device-manager__table__table__container">
        <table className="device-manager__table__table">
          <TableDataHeader
            withCheckbox
            tableHeaders={columns}
            markAllRowsAsSelected={ (event)=>dispatch(toggleSelectAllDeviceGroups(event.currentTarget.checked))}
            isSelectAllChecked={isSelectAllChecked}
          />
          <tbody>
            <RenderOnCondition condition={showResults}>
              {
                deviceGroups.map((deviceGroup: DeviceGroup) => (
                  <TableDataRow
                    deviceGroup={deviceGroup}
                    key={deviceGroup.id}
                    removeUnit={props.removeUnit}
                    selectOne={(deviceGroupId)=> dispatch(toggleSelectDeviceGroup(deviceGroupId))}
                  />
                  ))}
            </RenderOnCondition>
          </tbody>
        </table>
        <RenderOnCondition condition={!showResults}>
          <NoResultsInfo />
        </RenderOnCondition>
      </div>
    );
}

export default TableData;
