import * as React from 'react';
import { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { faFileExport, faFilter, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Device, DisplayTypes } from '@wiot/shared-domain/models/device/device';
import { clearPagination } from '../../state/actions/savePaginationAction';
import { AppState } from '../../state/reducers/rootReducer';
import { DeviceGroupExtended } from '../../state/types';
import { resetFilter } from '../../state/actions/changeFilterSortAction';
import Mobile from '../Responsive/Mobile';
import Desktop from '../Responsive/Desktop';
import RenderOnCondition from '../RenderOnCondition';
import { PageKeys } from '../../state/reducers/filterSortReducer';
import { resetDeviceManagerState } from '../../state/actions/deviceManagerStateAction';
import { setFilter } from '../../state/filter/set-filter-action-creator';
import MultiSelectPill from './MultiSelectPill';
import { getFilterComponent } from './filter-component-selector';
import { FilterField } from './filter-field';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { GROUP_PATH_DELIMITER } from '../../utils/device-group-path-helper';
import { SelectableOption } from '../FilterBar/select/selectable-option';
import { Tooltip } from '../shared/Tooltip';
import { getFilterBarFields } from './fields-provider/fields-provider';

export interface FilterBarProps {
  page: PageKeys;
  handleAddBtnClick?: () => void;
  handleDeviceExportBtnClick?: () => void;
}

function FilterBar(
  {
    page,
    handleAddBtnClick,
    handleDeviceExportBtnClick,
  }: FilterBarProps,
) {
  const dispatch = useDispatch();
  const filter = useSelector((state: AppState) => state.filters.filter);
  const isKeyManagerModeEnabled = useSelector((state: AppState) => !!state.siteSettings.isKeyManagerModeEnabled);
  const isMobileDisplay = useSelector((state: AppState) => state.isMobileDisplay);
  const selectedContentDisplay = useSelector((state: AppState) => state.deviceManagerState.contentDisplay);

  const persistPageState = useSelector((state: AppState) => state.currentUser.user?.settings?.display?.persistPageState);

  const [ filterFields, setFilterFields ] = useState<FilterField[]>([]);
  const [ isFilterOpened, setIsFilterOpened ] = useState<boolean>(!isMobileDisplay);

  const deviceGroups: DeviceGroupExtended[][] =
    // @ts-ignore The 'any' type in the global filter must be refactored
    'deviceGroup' in filter[page] ? filter[page].deviceGroup : [];
  const deviceTypes: SelectableOption[] =
    // @ts-ignore The 'any' type in the global filter must be refactored
    'deviceTypes' in filter[page] ? filter[page].deviceTypes : [];
  // @ts-ignore The 'any' type in the global filter must be refactored
  const deviceStatuses: SelectableOption[] = 'status' in filter[page] ? filter[page].status : [];
  const manufacturers: SelectableOption[] =
    // @ts-ignore The 'any' type in the global filter must be refactored
    'manufacturers' in filter[page] ? filter[page].manufacturers : [];
  // @ts-ignore The 'any' type in the global filter must be refactored
  const gateways: Device[] = 'gateways' in filter[page] ? filter[page].gateways : [];

  const isDeviceGroupSelectionVisible = deviceGroups && page !== PROPERTY_VIEW_PAGE_KEY && selectedContentDisplay !== DisplayTypes.UNASSIGNED
  const resetPageState = async () => {
    dispatch(resetFilter({ page })); // remove the  import renaming
    dispatch(clearPagination(page));
    if (page === 'device-manager') {
      dispatch(resetDeviceManagerState());
    }
  };

  const onUnload = () => {
    if (!persistPageState) {
      // Clear the previously saved filters, pagination and tabs if the persistPageState settings is turned off
      resetPageState();
    }
  };

  useEffect(() => {
    return onUnload;
  }, []);

  useEffect(() => {
    setFilterFields(getFilterBarFields(isKeyManagerModeEnabled, page, selectedContentDisplay));
  }, [isKeyManagerModeEnabled, page, persistPageState, selectedContentDisplay]);

  useEffect(() => {
    setIsFilterOpened(!isMobileDisplay);
  }, [isMobileDisplay]);

  const clearFilters = async () => {
    window.history.replaceState(null, '', window.location.pathname);
    dispatch(resetFilter({ page }));
  };

  const getDeviceGroupDisplayName = (groups: DeviceGroupExtended[]) =>
    groups.map((group) => group.name).join(GROUP_PATH_DELIMITER);

  const removeDeviceGroupItem = (index: number) => {
    // @ts-ignore
    const { deviceGroup } = filter[page];
    const updatedDeviceGroups = [ ...deviceGroup ];
    updatedDeviceGroups.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          deviceGroup: updatedDeviceGroups,
        },
      }),
    );
  };

  const removeDeviceTypeItem = (index: number) => {
    const updatedDeviceTypes = [ ...deviceTypes ];
    updatedDeviceTypes.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          deviceTypes: updatedDeviceTypes,
        },
      }),
    );
  };

  const removeGatewayItem = (index: number) => {
    const updatedGateways = [ ...gateways ];
    updatedGateways.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          gateways: updatedGateways,
        },
      }),
    );
  };

  const removeManufacturerItem = (index: number) => {
    const updatedManufacturers = [ ...manufacturers ];
    updatedManufacturers.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          manufacturers: updatedManufacturers,
        },
      }),
    );
  };

  const removeStatusItem = (index: number) => {
    const updatedStatus = [ ...deviceStatuses ];
    updatedStatus.splice(index, 1);
    dispatch(
      setFilter({
        page,
        values: {
          status: updatedStatus,
        },
      }),
    );
  };

  return (
    <>
      <RenderOnCondition condition={ filterFields && filterFields.length > 0 }>
        <div data-testid="filterbar" className="container-fluid">
          <Mobile>
            <Row className="filterbar-mobile-wrapper">
              <Col>
                <Tooltip
                  id="filter-mobile-button"
                  place="bottom"
                >
                  <Translate id="open-mobile-filter"/>
                </Tooltip>
                <button
                  className={ classNames('filterbar-mobile-label form__button--blue pr-3') }
                  onClick={ () => setIsFilterOpened(!isFilterOpened) }
                  data-tip="filter-mobile-button"
                  data-for="filter-mobile-button"
                >
                  <FontAwesomeIcon
                    className={ classNames('filterbar__icon', {
                      'text-color-main': isFilterOpened,
                    }) }
                    icon={ faFilter }
                  />
                  { ' ' }
                  <Translate id="filters"/>
                </button>
              </Col>
              <Col>
                <RenderOnCondition condition={ handleAddBtnClick }>
                  <Tooltip id="add-device-mobile-button">
                    <Translate id="add"/>
                  </Tooltip>
                  <button
                    className="fa-pull-right form__button--blue background-color-main text-color-white ml-3"
                    onClick={ handleAddBtnClick }
                    data-tip="add-device-mobile-button"
                    data-for="add-device-mobile-button"
                  >
                    <FontAwesomeIcon
                      className={ classNames('fa-pull-right', 'filterbar__icon') }
                      icon={ faPlus }
                    />
                  </button>
                </RenderOnCondition>
                <RenderOnCondition condition={ handleDeviceExportBtnClick && !isKeyManagerModeEnabled }>
                  <Tooltip id="export-devices-mobile-button">
                    <Translate id="export"/>
                  </Tooltip>
                  <button
                    className="fa-pull-right form__button--blue background-color-main text-color-white"
                    onClick={ handleDeviceExportBtnClick }
                    data-tip="export-devices-mobile-button"
                    data-for="export-devices-mobile-button"
                  >
                    <FontAwesomeIcon
                      className={ classNames('fa-pull-right', 'filterbar__icon') }
                      icon={ faFileExport }
                    />
                  </button>
                </RenderOnCondition>
              </Col>
            </Row>
          </Mobile>
          <RenderOnCondition condition={ isFilterOpened }>
            <>
              <Row>
                <RenderOnCondition condition={ isDeviceGroupSelectionVisible }>
                  <MultiSelectPill
                    items={ deviceGroups } fieldTranslationId="device-group"
                    handleRemoveClick={ (index) => removeDeviceGroupItem(index) }
                    getSelectedValueText={
                      (selectedValue) => getDeviceGroupDisplayName(selectedValue) }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ deviceTypes }>
                  <MultiSelectPill
                    items={ deviceTypes } fieldTranslationId="device-type"
                    handleRemoveClick={ (index) => removeDeviceTypeItem(index) }
                    getSelectedValueText={ (selectedValue) => `${ selectedValue.label }` }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ gateways }>
                  <MultiSelectPill
                    items={ gateways } fieldTranslationId="gateways"
                    handleRemoveClick={ (index) => removeGatewayItem(index) }
                    getSelectedValueText={ (selectedValue) => `${ selectedValue.label }` }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ manufacturers }>
                  <MultiSelectPill
                    items={ manufacturers } fieldTranslationId="manufacturer"
                    handleRemoveClick={ (index) => removeManufacturerItem(index) }
                    getSelectedValueText={ (selectedValue) => `${ selectedValue.label }` }
                  />
                </RenderOnCondition>
                <RenderOnCondition condition={ deviceStatuses }>
                  <MultiSelectPill
                    items={ deviceStatuses } fieldTranslationId="status"
                    handleRemoveClick={ (index) => removeStatusItem(index) }
                    getSelectedValueText={ (selectedValue) => `${ selectedValue.label }` }
                  />
                </RenderOnCondition>
              </Row>
              <Row className="filterbar">
                <Desktop>
                  <Col className="flex-grow-0 pr-0 filterbar-title">
                    <FontAwesomeIcon icon={ faFilter } className="text-color-main"/>
                    { ' ' }
                    <Translate id="filters"/>
                  </Col>
                </Desktop>
                { filterFields.map((field: FilterField) => {
                  return (
                    <Col
                      className="filterbar__input-wrapper"
                      md={ 12 }
                      lg="auto"
                      key={ field.name }
                    >
                      { getFilterComponent(field, page) }
                    </Col>
                  );
                }) }
                <Col lg={ 1 } className="filterbar__btn_wrapper">
                  <div className="filterbar__item">
                    <Tooltip id="filter-apply-button">
                      <Translate id="apply-filter"/>
                    </Tooltip>
                  </div>
                  <div className="filterbar__item">
                    <Tooltip id="filter-reset-button">
                      <Translate id="reset-filter"/>
                    </Tooltip>
                    <button
                      className="form__button"
                      onClick={ clearFilters }
                      data-tip="filter-reset-button"
                      data-for="filter-reset-button"
                    >
                      <FontAwesomeIcon icon={ faTrashAlt }/>
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          </RenderOnCondition>
        </div>
      </RenderOnCondition>
    </>
  );
}

export default FilterBar;
