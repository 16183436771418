import { connect, useDispatch, useSelector, } from 'react-redux';
import React, { useEffect, useState } from 'react';

import FilterBar from '../../components/Filter/FilterBar';
import Table from '../../components/Table/Table';
import { hasPermission } from '../../utils/common';
import { removeDeviceGroupFromDB } from '../../api/apiHelpers';
import TableDataDevGroup from './Table/TableDataDevGroups';
import { AppState } from '../../state/reducers/rootReducer';
import { isTableLoading } from '../../state/table/isTableLoadingAction';
import ErrorBoundary from '../../components/ErrorBoundary';
import Desktop from '../../components/Responsive/Desktop';
import Mobile from '../../components/Responsive/Mobile';
import MobileTable from './Mobile/MobileTable';
import RenderOnCondition from '../../components/RenderOnCondition';
import DeviceGroupActionModal from './DeviceGroupActionModal';
import MainGrid from '../../components/shared/MainGrid';
import { updateDeviceGroupFilter } from '../../state/filter/updateDeviceGroupFilterActionCreator';
import PropertyViewSettingsModal from '../PropertyView/Consumption/Table/PropertyViewSettingsModal';
import { handleDeviceGroupDeletionResponse } from '../../state/device-group/deviceGroupRemovalResponseHandler';
import useTranslation from '../../hooks/useTranslation';
import { DEVICE_GROUPS_PAGE_NAME } from '../../state/device-group/device-group-page-name';
import { fetchDeviceGroups } from '../../state/device-group/paginated-device-groups/fetchDeviceGroupsActionCreators';
import { withLocalize } from 'react-localize-redux';

const DeviceGroups = () => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const selectedItems = useSelector((state: AppState) => state.paginatedDeviceGroups.selectedItems);
  const currentEntriesPerPage = useSelector((state: AppState) => state.currentEntriesPerPage);
  const currentPage = useSelector((state: AppState) => state.pagination[DEVICE_GROUPS_PAGE_NAME].currentPage);
  const filter = useSelector((state: AppState) => state.filters.filter[DEVICE_GROUPS_PAGE_NAME]);
  const permission = useSelector((state: AppState) => state.currentUser.permission);
  const totalDocs = useSelector((state: AppState) => state.pagination[DEVICE_GROUPS_PAGE_NAME].totalDocs);
  const isPropertySettingsModalVisible = useSelector((state: AppState) => state.properties.settingsModal.isVisible);

  const [showAddModal, setShowAddModal] = useState(false);


  useEffect(() => {
    dispatch(updateDeviceGroupFilter());
  }, []);

  useEffect(() => {
    dispatch(fetchDeviceGroups());
  }, [
    currentEntriesPerPage,
    currentPage,
    filter,
  ]);

  const removeUnit = async (id: string, refreshDeviceGroups: boolean = true) => {
    dispatch(isTableLoading(true));
    const response = await removeDeviceGroupFromDB(id);
    const isDeviceGroupRemoved = handleDeviceGroupDeletionResponse(translate, response);
    if (isDeviceGroupRemoved && refreshDeviceGroups) {
      dispatch(fetchDeviceGroups());
    }
    dispatch(isTableLoading(false));
  };


  const handleBulkRemove = async () => {
    const removePromises = selectedItems.map(async (deviceGroup) =>
      removeUnit(deviceGroup.id!, false),
    );
    await Promise.all(removePromises);
    dispatch(fetchDeviceGroups());
  };


  const toggleShowAddModal = () => {
    setShowAddModal((prevState) => !prevState);
  };

  const getTableComponent = () => (
    <TableDataDevGroup
      removeUnit={ (id) => removeUnit(id) }
    />
  );

  return (
    <MainGrid dataTestId="page-device-groups">
      <ErrorBoundary>
        <FilterBar page="device-groups" handleAddBtnClick={ toggleShowAddModal }/>
      </ErrorBoundary>
      <Desktop>
        <ErrorBoundary>
          <Table
            page="device-groups"
            changeView={ false }
            addModal={ hasPermission(permission, 'deviceGroups.add') }
            addText="group"
            tableComponent={ getTableComponent() }
            selectedRows={ selectedItems }
            handleBulkRemove={ handleBulkRemove }
            elementType="device-groups"
            showPagination
            refreshTableData={ () => dispatch(fetchDeviceGroups()) }
            editorModalCreator={ (toggleShowEditorModal) => (
              <DeviceGroupActionModal
                closeAddAndUpdateModal={ toggleShowEditorModal }
                title="add-group"
                showDeleteButton={ false }
                addUnit
                refreshGroups={ () => dispatch(fetchDeviceGroups()) }
              />
            ) }
          />
        </ErrorBoundary>
      </Desktop>
      <Mobile>
        <>
          <MobileTable
            removeUnit={ (id) => removeUnit(id) }
            refreshData={ () => dispatch(fetchDeviceGroups()) }
            totalDocs={ totalDocs }
          />
          <RenderOnCondition condition={ showAddModal }>
            <DeviceGroupActionModal
              closeAddAndUpdateModal={ toggleShowAddModal }
              title="add-group"
              showDeleteButton={ false }
              addUnit
              refreshGroups={ () => dispatch(fetchDeviceGroups()) }
            />
          </RenderOnCondition>
        </>
      </Mobile>

      <RenderOnCondition condition={ isPropertySettingsModalVisible }>
        <PropertyViewSettingsModal/>
      </RenderOnCondition>
    </MainGrid>
  );
};

export default connect()(withLocalize(DeviceGroups));
