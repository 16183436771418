import { FeatureAvailability } from '../../../utils/feature-availability';
import { PROPERTY_VIEW_PAGE_KEY } from '@wiot/shared-domain/domain/property/property-view-page-key';
import { Fields } from '../filter-field';

export const allFilterBarFields: Fields = {
  'device-manager': [
    {
      name: 'device-name-id',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'device-group',
      component: 'advanced-multi-select',
      stateField: 'deviceGroup',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'device-type',
      component: 'multi-device-type-select',
      stateField: 'deviceType',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'gateways',
      component: 'gateway-select',
      stateField: 'gateways',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'manufacturers',
      component: 'manufacturer-select',
      stateField: 'manufacturers',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'status',
      component: 'multi-device-status-select',
      stateField: 'status',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
  ],
  'device-messages': [
    {
      name: 'deviceId',
      component: 'async-device-select',
      stateField: 'device',
      valueKey: 'id',
      optionFromKey: 'id',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'device-group',
      component: 'advanced-multi-select',
      stateField: 'deviceGroup',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'device-type',
      component: 'multi-device-type-select',
      stateField: 'deviceType',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'gateways',
      component: 'gateway-select',
      stateField: 'gateways',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'received-at-gte',
      component: 'date-range',
      stateField: 'receivedAt_gte',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
  ],
  'device-groups': [
    {
      name: 'name',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'group-type',
      component: 'group-type-select',
      stateField: 'type',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
  ],
  'manage-device-types': [
    {
      name: 'name',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    }],
  'manage-device-group-types': [
    {
      name: 'name',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    }],
  'user-management': [
    {
      name: 'user-name',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'email',
      component: 'input',
      stateField: 'email',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
    {
      name: 'verified',
      component: 'boolean-select',
      stateField: 'validated',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
  ],
  roles: [{
    name: 'role-name',
    component: 'input',
    stateField: 'name',
    availability: FeatureAvailability.Desktop
      | FeatureAvailability.Mobile
      | FeatureAvailability.Wiot
      | FeatureAvailability.KeyManager,
  }],
  'key-management': [
    {
      name: 'key-name',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    // { name: 'device-group', stateField: 'deviceGroup' }
  ],
  'data-integration': [
    {
      name: 'description',
      component: 'input',
      stateField: 'name',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
        | FeatureAvailability.KeyManager,
    },
  ],
  [PROPERTY_VIEW_PAGE_KEY]: [
    {
      name: 'property',
      component: 'property-select',
      stateField: 'property',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot
    },
    {
      name: 'consumptionType',
      component: 'consumption-type-toggle-filter',
      stateField: 'consumptionType',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'billingFrequency',
      component: 'billing-frequency-toggle',
      stateField: 'billingFrequency',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
    {
      name: 'billingPeriodStartDate',
      component: 'billing-period-picker',
      stateField: 'billingPeriodStartDate',
      availability: FeatureAvailability.Desktop
        | FeatureAvailability.Mobile
        | FeatureAvailability.Wiot,
    },
  ],
};
