/**
 * @deprecated
 * Do not use this file, instead create a folder for each reducer and put all actions, actionCreators and reducers in there
 */

export const SAVE_DEVICES = 'SAVE_DEVICES';
export const SAVE_DEVICE_MESSAGES = 'SAVE_DEVICE_MESSAGES';
export const ADD_DEVICE_TYPE = 'ADD_DEVICE_TYPE';
export const SAVE_USERS = 'SAVE_USERS';
export const SAVE_USER_ROLES = 'SAVE_USER_ROLES';
export const SAVE_ROLES = 'SAVE_ROLES';
export const SAVE_KEYS = 'SAVE_KEYS';
export const SAVE_DATA_INTEGRATION_JOBS = 'SAVE_DATA_INTEGRATION_JOBS';
export const TOGGLE_SELECT_ALL_DEVICES = 'TOGGLE_SELECT_ALL_DEVICES';
export const TOGGLE_SELECT_DEVICE = 'TOGGLE_SELECT_DEVICE';
export const TOGGLE_SELECT_ALL_USERS = 'TOGGLE_SELECT_ALL_USERS';
export const TOGGLE_SELECT_USER = 'TOGGLE_SELECT_USER';
export const TOGGLE_SELECT_ALL_ROLES = 'TOGGLE_SELECT_ALL_ROLES';
export const TOGGLE_SELECT_ROLE = 'TOGGLE_SELECT_ROLE';
export const TOGGLE_SELECT_ALL_KEYS = 'TOGGLE_SELECT_ALL_KEYS';
export const TOGGLE_SELECT_KEY = 'TOGGLE_SELECT_KEY';
export const TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS = 'TOGGLE_SELECT_ALL_DATA_INTEGRATION_JOBS';
export const TOGGLE_SELECT_DATA_INTEGRATION_JOB = 'TOGGLE_SELECT_DATA_INTEGRATION_JOB';
export const TRY_LOGIN = 'TRY_LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHANGE_ENTRIES_PER_PAGE = 'CHANGE_ENTRIES_PER_PAGE';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const CHANGE_SORT = 'CHANGE_SORT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PERMISSION = 'SET_USER_PERMISSION';
export const SET_RECOMMENDED_ENTRIES_PER_PAGE = 'SET_RECOMMENDED_ENTRIES_PER_PAGE';
export const SET_MODAL_ZINDEX = 'SET_MODAL_ZINDEX';
export const SET_CURRENT_PAGETITLE = 'SET_CURRENT_PAGETITLE';
export const SET_SITE_SETTINGS = 'SET_SITE_SETTINGS';
export const SAVE_PAGINATION = 'SAVE_PAGINATION';
export const CLEAR_PAGINATION = 'CLEAR_PAGINATION';
export const SAVE_CURRENT_PAGE = 'SAVE_CURRENT_PAGE';
export const IS_LOADING = 'IS_LOADING';
export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';
export const IS_DEVICE_TREE_LOADING = 'IS_DEVICE_TREE_LOADING';
export const CHANGE_ONLINE_STATUS = 'CHANGE_ONLINE_STATUS';
export const IS_MOBILE_DISPLAY = 'IS_MOBILE_DISPLAY';
export const IS_OFFLINE = 'IS_OFFLINE';
export const SET_DEVICE_MANAGER_VISIBLE_VIEW = 'SET_DEVICE_MANAGER_VISIBLE_VIEW';
export const SET_DEVICE_MANAGER_CONTENT_DISPLAY = 'SET_DEVICE_MANAGER_CONTENT_DISPLAY';
export const RESET_DEVICE_MANAGER_STATE = 'RESET_DEVICE_MANAGER_STATE';

export const FETCH_PERMITTED_ROLES = 'FETCH_PERMITTED_ROLES';
export const PERMITTED_ROLES_FETCHED = 'PERMITTED_ROLES_FETCHED';
export const FETCH_EDIT_USER_WITH_ROLES = 'FETCH_EDIT_USER_WITH_ROLES';
export const EDIT_USER_FETCHED_WITH_ROLES = 'EDIT_USER_FETCHED_WITH_ROLES';
export const FETCH_DEVICE_KEY_PROTECTION_INFO = 'FETCH_DEVICE_KEY_PROTECTION_INFO';
export const FETCH_DEVICES = 'FETCH_DEVICES';
export const FETCH_DEVICE_GROUP_TREE = 'FETCH_DEVICE_GROUP_TREE';
export const TOGGLE_SHOW_DELETE_MODAL = 'TOGGLE_SHOW_DELETE_MODAL';
export const RESET_USER_ACTION_MODAL_STATE = 'RESET_USER_ACTION_MODAL_STATE';
export const SET_LOADING_INDICATOR = 'SET_LOADING_INDICATOR ';
export const SERVER_FRONTEND_VERSION_FETCHED = 'SERVER_FRONTEND_VERSION_FETCHED';
