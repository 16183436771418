import { DeviceGroupExtended } from '../../types';
import {
  DEVICE_GROUPS_FETCH_FAILED,
  DEVICE_GROUPS_FETCHED, DEVICE_GROUPS_RESET,
  FETCH_DEVICE_GROUPS,
  FetchDeviceGroupsActionTypes, TOGGLE_SELECT_ALL_DEVICE_GROUPS, TOGGLE_SELECT_DEVICE_GROUP
} from './paginated-device-group-action-types';


type DeviceGroupsState = {
  currentPageItems: DeviceGroupExtended[];
  selectedItems: DeviceGroupExtended[];
  isLoadingDeviceGroups: boolean;
};

const initialState: DeviceGroupsState = {
  currentPageItems: [],
  selectedItems: [],
  isLoadingDeviceGroups: false,
};

export const paginatedDeviceGroupsReducer = (
  state: DeviceGroupsState = initialState,
  action: FetchDeviceGroupsActionTypes,
): DeviceGroupsState => {
  switch (action.type) {
    case FETCH_DEVICE_GROUPS:
      return { ...state, isLoadingDeviceGroups: true };

    case DEVICE_GROUPS_FETCHED:
      return {
        ...state,
        currentPageItems: action.deviceGroups.deviceGroups,
        selectedItems: [],
        isLoadingDeviceGroups: false,
      };

    case DEVICE_GROUPS_FETCH_FAILED:
      return { ...state, isLoadingDeviceGroups: false };


    case TOGGLE_SELECT_DEVICE_GROUP:
      const updatedList = toggleSelectionOfOneGroup(action.deviceGroupId, [...state.currentPageItems]);
      return {
        ...state,
        currentPageItems: updatedList,
        selectedItems: updatedList.filter(deviceGroup => deviceGroup.checked)
      };

    case TOGGLE_SELECT_ALL_DEVICE_GROUPS:
      const updated = toggleSelectionOfAllGroups(action.newValue, [...state.currentPageItems]);
      return {
        ...state,
        currentPageItems: updated,
        selectedItems: updated.filter(deviceGroup => deviceGroup.checked)
      };

    case DEVICE_GROUPS_RESET:
      return initialState;

    default:
      return state;
  }
};

const toggleSelectionOfOneGroup = (
  deviceGroupId: string,
  list: DeviceGroupExtended[]
): DeviceGroupExtended[] => {
  const updatedList = [...list].map(
    deviceGroup => {
      if (deviceGroup.id === deviceGroupId) {
        deviceGroup.checked = !deviceGroup.checked;
      }

      return deviceGroup;
    }
  );

  return updatedList;
};

const toggleSelectionOfAllGroups = (
  newValue: boolean,
  list: DeviceGroupExtended[]
): DeviceGroupExtended[] => {
  const updatedList = [...list].map(deviceGroup => (
    { ...deviceGroup, checked: newValue }
  ));

  return updatedList;
};
